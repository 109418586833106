import * as actions from '../actions';

const NO_STANDINGS = {
  standings: [],
  users: {},
  clubs: [],
};

function initializeStandings(state, standings) {
  return {
    ...standings
  };
}

export default function images(state = NO_STANDINGS, action) {
  switch (action.type) {

    case actions.INIT_STANDINGS:
      return initializeStandings(state, action.payload);

    default:
      break;
  }

  return state;
}
