import React from 'react';

import AppController from '../../../Controllers/AppController';


import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import LiveViewConsoleProps from '../LiveViewConsoleProps';

export default function CommandButton(props)  {

      const enabled = props.isOperator && (props.eventState === 'upcoming' || props.paused);
      const backgroundColor = enabled ? 'limegreen' : 'gray';
      const styles = {
        p: 2,
        width: 'calc(100% - 5em)',
        height: 'calc(100% - 1em)',
        border: '1px dashed white',
        backgroundColor,
        marginRight: 'auto',
        marginLeft: 'auto',
        color: 'white',
        ":hover": {
          backgroundColor: 'darkgreen'
        },
        ":disabled": {
          backgroundColor: 'red',
          color: 'white'
        }
      };

      let text = props.isOperator ? "Start" : "About to begin";
      if (props.paused) {
        if (props.isOperator) {
          text = "Resume";
        } else {
          text = "About to resume";
        }
      } else if (props.eventState === 'scoring-test') {
        text = "Testing...";
      } else if (['running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners'].includes(props.eventState)) {
        text = "In Progress";
      } else if (props.eventState === 'choosing-best') {
        text = "Choosing Best...";
      } else if (props.eventState === 'report-winners') {
        if (props.isOperator) {
          text = `Start Next ${props.categoryArchetype}`;
        } else {
          text = "About to resume";
        }
      } else if (props.eventState === 'ended') {
        text = "Event Ended";
      } else if (props.isOperator && props.canPrep && !props.labelInfo) {
        text = "Prep";
      }

      const resumeGame = () => {
        const resumableStates = [
          'upcoming',
          'scoring-test',
          'report-winners',
          'choosing-best',
          'preview-images',
          'ended'
        ];
    
        // this isn't working...
    
        const canResume = resumableStates.includes(props.eventState);
    
        if (!canResume && props.canPrepMidCycleReport) {
          const currentReportName = props.getCurrentMidCycleReportId();
          const reportUrl = props.labelInfo && props.labelInfo[currentReportName];
          if (!reportUrl) {
            AppController.emit(AppController.errorEvent, `Cannot resume until you prepare the mid-cycle report.`);
            return;
          }
    
          if (props.eventState === 'intermission'
            && props.toGo > 0
            && props.modality === 'projected-image') {
            AppController.emit(AppController.errorEvent, `You need to preview images in this round before resuming game play`);
            return;
          }
        }
        props.resumeGame();
      };

      const handleClick = () => {
        if (props.paused) {
          resumeGame();
        } else if (props.canPrep && !props.labelInfo) {
          props.prepGame();
        } else {
          props.startGame();
        }
      };

      return (
        <Button key="startButton" disabled={!enabled} sx={styles} onClick={handleClick}>
          <Typography sx={{ fontWeight: 400, fontSize: '4rem' }} variant="h1">
            {text}
          </Typography>
        </Button>
      );
}

CommandButton.propTypes = LiveViewConsoleProps.propTypes;
CommandButton.defaultProps = LiveViewConsoleProps.defaultProps;
