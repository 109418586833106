import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

import LiveViewConsoleProps from '../LiveViewConsoleProps';

import styles from '../LiveViewConsole.module.scss';


export default function RoundInformation(props)  {
    const folded = props.eventFeatures.includes('fold-all-categories');

    const renderBestCard = () => {
        if (!props.bestInShow) {
          return;
        }
        const image = props.getImageInfo(props.bestInShow);
        return (
          <Card sx={{
            maxWidth: 'unset',
            minWidth: 'unset',
            maxHeight: 'unset',
            minHeight: 'unset',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'dodgerblue',
              '*': {
                color: 'white',
                borderColor: 'white',
              },
            }
          }} onClick={(e) => {
            props.openDirectory(props.match.params.id);
          }}>
            <CardHeader title={`${image.title} by ${image.maker}`} subheader={image.category}>
    
            </CardHeader>
            <CardMedia
              component="img"
              alt={image.title}
              height="200"
              image={image.url}
            />
            <div className={styles.cardContent}>
              <Typography variant="h5">
                {props.specialAwardTitle}
              </Typography>
            </div>
          </Card>
        );
      };

    if (['report-winners'].includes(props.eventState)) {
      const categoryInfo = !folded && (
        <Typography key="category" variant="h6">
            {props.categoryArchetype}: <Typography variant="span" sx={{ color: `red` }}>{props.categoryDisplayNames[props.currentCategory] || props.currentCategory}</Typography>
          </Typography>
      );
      return (
        <Box>
          <Typography key="title" variant="h5">
            {props.title}
          </Typography>
          {categoryInfo}
          <Typography key="round" variant="h6">
            Round:  <Typography variant="span" sx={{ color: `red` }}>ReportingWinners</Typography>
          </Typography>
          <Typography key="imageCount" variant="h6">
            Total images:  <Typography variant="span" sx={{ color: `red` }}>{props.totalImagesInCategory}</Typography>
          </Typography>
          <Typography key="disqualificationCount" variant="h6">
            Disqualifications:  <Typography variant="span" sx={{ color: `red` }}>{props.totalDqInCategory}</Typography>
          </Typography>
          <Typography key="roundCount" variant="h6">
            Total winners:  <Typography variant="span" sx={{ color: `red` }}>{props.imagesInRound.length}</Typography>
          </Typography>
        </Box>
      );
    } else if (['upcoming', 'scoring-test', 'choosing-best'].includes(props.eventState) || !props.currentCategory) {
      return (
        <Typography key="title" variant="h5">
          {props.title}
        </Typography>
      );
    } else if (props.eventState === 'ended') {
      return (
        <React.Fragment>
          <Typography key="title" variant="h5">
            {props.title}
          </Typography>
          {renderBestCard()}
        </React.Fragment>
      );

    } else {
      const extras = [];

      const choosingStates = [
        'running',
        'scoring-images',
      ];
  
      if (props.scoringType === 'manual'
        && props.modality === 'printed-image'
        && choosingStates.includes(props.eventState)
        && props.scoringRound === 'FindWinners') {
  
        const index = props.imagesInRound.length - props.totalImagesThisRound;
        const places = props.placesNames.slice(index);
        const place = (places.length > 1) ? `${places[0]}, ...` : places.shift();
  
        extras.push(
          <Typography key="choosingHelp" variant="h6">
            Choosing:  <Typography variant="span" sx={{ color: `red` }}>{place}</Typography>
          </Typography>
        );
      }
  
      const categoryInfo = !folded && (
        <Typography key="Judging" variant="h6">
          {props.categoryArchetype}: <Typography variant="span" sx={{ color: `red` }}>{props.categoryDisplayNames[props.currentCategory] || props.currentCategory}</Typography>
        </Typography>
      );

      return (
        <Box>
          <Typography key="title" variant="h5">
            {props.title}
          </Typography>
          {categoryInfo}
          <Typography key="Round" variant="h6">
            Round:  <Typography variant="span" sx={{ color: `red` }}>{props.scoringRound}</Typography>
          </Typography>
          <Typography key="imageCount" variant="h6">
            Total images:  <Typography variant="span" sx={{ color: `red` }}>{props.totalImagesInCategory}</Typography>
          </Typography>
          <Typography key="disqualificationCount" variant="h6">
            Disqualifications:  <Typography variant="span" sx={{ color: `red` }}>{props.totalDqInCategory}</Typography>
          </Typography>
          <Typography key="roundCount" variant="h6">
            Total round:  <Typography variant="span" sx={{ color: `red` }}>{props.totalImagesThisRound}</Typography>
          </Typography>
          <Typography key="scoreCount" variant="h6">
            To Score:  <Typography variant="span" sx={{ color: `red` }}>{props.toGo}</Typography>
          </Typography>
          {extras}
        </Box>
      );
    }
}

RoundInformation.propTypes = LiveViewConsoleProps.propTypes;
RoundInformation.defaultProps = LiveViewConsoleProps.defaultProps;
