import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { GlobalHotKeys } from 'react-hotkeys';

import { DataGrid } from '@mui/x-data-grid';

import lodash from 'lodash';

import CarouselViewIcon from '@mui/icons-material/ViewCarouselOutlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import CheckIcon from '@mui/icons-material/Check';

import {
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import Interstitial from '../../Components/Interstitial';

import Header from '../Header';
import Footer from '../Footer';

import * as actions from '../../model/actions';

import { openGridPrintView } from "../../Utils/ReportUtils";

import styles from './WinnersShowcaseView.module.scss';

class WinnersShowcaseView extends Component {

  constructor(props) {
    super(props);

    this.swiperRef = React.createRef();

    this.settings = {
      autoPlay: false,
      animation: "slide",
      indicators: true,
      duration: 500,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: false,
      cycleNavigation: true,
      fullHeightHover: true,
      swipe: true
    };

    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);

    this.renderImageCard = this.renderImageCard.bind(this);
    this.renderSwiperImageCard = this.renderSwiperImageCard.bind(this);

    this.keyMap = {
      Next: 'ArrowRight',
      Prev: 'ArrowLeft'
    };

    this.handlers = {
      Next: this.handleNextSlide,
      Prev: this.handlePrevSlide,
    };

    this.scoreSheetKeyMap = {
      Print: 'command+p',
    };

    this.handlePrint = undefined;

  }

  get category() {
    return this.props.match.params.category || this.props.defaultCategory;
  }

  renderPleaseStandBy() {
    return (
      <Interstitial />
    );
  }

  renderImageCard(image, index) {
    if (!image) {
      return null;
    }
    const place = image.place ? `(${image.place})` : null;
    const content = (
      <Grid item xs={4} key="content" className={styles.ContentGrid}>
        <CardContent className={styles.Content}>
          <CardActions className={styles.Actions}>
            <Typography variant="body2" component="span">
              {image.title} {place} by {image.maker}
            </Typography>
          </CardActions>
        </CardContent>
      </Grid>
    );

    const media = (
      <Grid item xs={4} key={image.id} className={styles.MediaGrid}>
        <CardMedia
          className={styles.Media}
          image={image.url}
        >
        </CardMedia>
      </Grid>
    );

    return (
      <Card raised className={styles.Banner} key={`image-${index}`}>
        <Grid container spacing={0} className={styles.BannerGrid}>
          {[media, content]}
        </Grid>
      </Card>
    );
  }

  doPrintWithContext(title, columns, rows, options)  {
    //
    openGridPrintView(title, columns, rows, options);
  }

  renderSwiperImageCard(image, index) {
    return (
      <SwiperSlide key={index}>
        {this.renderImageCard(image, index)}
      </SwiperSlide>
    );
  }

  get images() {
    if (this.props.eventType === 'top-honors' && this.onlyShowHonoredImages) {
      return this.props.honors[this.category]
        .map(id => this.props.getImageInfo(id))
        .filter(image => Boolean(image) && image.url)
        .map((image, index) => {
          const score = this.props.scores[image.id];
          return {
            ...image,
            score,
            place: ''
          };
        })
        .sort((a, b) => {
          return (
            b.score - a.score
          );
        });
    }
    let images = Object.entries(this.props.scores)
      .map(([id, score]) => {
        const image = this.props.getImageInfo(id);
        return {
          ...image,
          score
        };
      })
      .filter(image => image.url)
      .filter(image => image.category === this.category || this.props.eventFeatures.includes('fold-all-categories'))
      .sort((a, b) => {
        return (
          b.score - a.score
        );
      })
      .map((image, index) => {
        if (this.props.eventType === 'honors-competition') {
          const placeName = this.props.getAwardById(image.id);
          let place = this.props.awardMap[placeName] || placeName;

          if (this.props.bestInShow === image.id) {
            place = `${place} and ${this.props.specialAwardTitle}`;
          }

          return {
            ...image,
            place,
          };
        } else  if (this.props.eventType === 'placed-competition-with-mentions') {
          const placeName = this.props.getAwardById(image.id);
          let place = this.props.awardMap[placeName] || placeName;

          if (this.props.bestInShow === image.id) {
            place = `${place} and ${this.props.specialAwardTitle}`;
          }

          return {
            ...image,
            place,
          };
        } else if (this.props.eventType === 'placed-competition') {
            let place = this.props.placesNames.slice(index).shift() || '';

            if (this.props.bestInShow === image.id) {
              place = `${place} and ${this.props.specialAwardTitle}`;
            }

            return {
              ...image,
              place,
            };
        } else {
          return image;
        }
      });

      if (this.props.eventType === 'placed-competition-with-mentions') {
        // we have to sort this based on order of awards, not scores...
        images = Object.entries(this.props.awards)
                            .sort((a, b) => a[1] - b[1])
                            .map(a => a[0])
                            .map(id => images.find(image => image.id === id));
        
        if (this.includeBestInShowAsSpecialAward) {
            images.unshift({
              ...this.props.getImageInfo(this.props.bestInShow),
              place: this.props.specialAwardTitle
            });
        }
      }

      return images;
  }

  get includeBestInShowAsSpecialAward() {
    // this needs to be fixed...
    return this.props.bestInShow && 
      this.props.getImageInfo(this.props.bestInShow) &&
      this.props.specialAwardCategory === this.props.getImageInfo(this.props.bestInShow).category  &&
      this.props.eventFeatures.includes('showcase-special-award') &&
      this.props.eventFeatures.includes('best-in-show-is-special-award') &&
      this.props.eventFeatures.includes('special-award');
  }

  get filteredImages() {

    if (this.props.eventType === 'placed-competition-with-mentions') {
      return this.images;
    }

    if (this.props.eventType === 'placed-competition') {
      const places = this.props.placesNames.length;
      return this.images.slice(0, places);
    }

    if (this.props.eventType === 'honors-competition') {
      return this.images.filter(image => Boolean(image.place));
    } 

    if (this.props.eventType === 'top-honors') {
      return (this.props.honors[this.category] || [])
        .map(id => this.props.getImageInfo(id))
        .filter(image => Boolean(image));
    }

    return this.images.slice(0, this.props.totalHonors);
  }

  handleNextSlide() {
    this.swiperRef.current.swiper.slideNext(300);
  }
  handlePrevSlide() {
    this.swiperRef.current.swiper.slidePrev(300);
  }

  renderSwiper() {
    return (
      <GlobalHotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <Swiper
          pagination={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          className={styles.Swiper}
          ref={this.swiperRef}
        >
          {
            this.filteredImages.map(this.renderSwiperImageCard)
          }
        </Swiper>
        <br />
      </GlobalHotKeys>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Well crap, that didn't work...
        </Typography>
      </div>
    );
  }

  renderScoreSheet() {

    const columns = [
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      type: 'string',
    },
    {
      field: 'maker',
      headerName: 'Maker',
      width: 250,
      type: 'string',
    },
    {
      field: 'score',
      headerName: 'Final Score',
      width: 100,
      type: 'number',
      renderCell: (params) => {
        if (params.value === undefined) {
          return '--';
        }
        return params.value.toString();
      },
    }];

    if (this.props.eventType !== 'top-honors') {
      columns.unshift({
        field: 'place',
        headerName: 'Place',
        width: 150,
        type: 'string',
      });
    } else {
      columns.unshift({
        field: 'advanced',
        headerName: '',
        width: 10,
        renderHtml: (params) => (
          `<div style="color: limegreen">
              ${this.props.honors[this.category].includes(params.row.id) ? "&#x2714;" : ""}
          </div>`
        ),
        renderCell: (params) => (
          this.props.honors[this.category].includes(params.row.id)
            ? <CheckIcon style={ {color: "limegreen"} }/> 
            : null
        ),
      });
    }

    const categoryName = this.props.categoryDisplayNames[this.category] || lodash.startCase(this.category);
    const title = `${this.props.campaignName} ${this.props.categoryArchetype} ${categoryName} Results`;


    const handleOpenDirectory = () => {
      this.props.openDirectory(this.props.match.params.campaignId);
    };

    this.handlePrint = () => {
      this.doPrintWithContext(title, columns, this.images);
    };

    this.scoreSheetHandlers = {
      Print: this.handlePrint.bind(this)
    };

    return (
      <GlobalHotKeys keyMap={this.scoreSheetKeyMap} handlers={this.scoreSheetHandlers}>
        <Box className={styles.scoreSheet}>
          <Typography sx={{ 
            marginTop: '8px', 
            marginLeft: '8px', 
            minWidth: 'calc(100vw - 16px)',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',  
          }} variant="h6">{title}</Typography>
          <Box>
            <DataGrid
              rows={this.images}
              columns={columns}
              autoPageSize
            />
          </Box>
          <Box sx={{ marginLeft: '8px', height: '4em', display: 'flex', alignItems: 'center' }}>
            <Button sx={{ height: '4em' }} onClick={handleOpenDirectory}>Directory</Button>
            <Button sx={{ height: '4em' }} onClick={this.handlePrint}>Print</Button>
          </Box>
        </Box>
     </GlobalHotKeys>

    );

  }

  renderStandings() {
    const columns = [
      {
        field: 'avatar',
        headerName: '',
        width: 16,
        type: 'string',
        renderCell: (params) => {
          const styleObj = {
            backgroundImage: `url(${params.value})`
          };
          return (
            <div className={styles.avatar} style={styleObj}></div>
          );
        },
      },
      {
        field: 'club',
        headerName: 'Club',
        width: 250,
        type: 'string',
      },
      {
        field: 'points',
        headerName: 'Points',
        width: 100,
        type: 'number',
        renderCell: (params) => {
          return params.value.toString();
        },
      },];

    const handleShowCompleteStandings = () => {
      this.props.openCompleteStandings(this.props.initiativeId);
    };


    const emptyClubScores = this.props.clubs.reduce((acc, current) => {
      acc[current.id] = {
        points: 0,
        avatar: current.avatar
      };
      return acc;
    }, {});

    const data = Object.entries(
      this.images.reduce((acc, current) => {
        if (!acc[current.club]) {
          acc[current.club] = {
            points: current.score,
            avatar: current.avatar
          };
        } else {
          acc[current.club].points += current.score;
        }
        return acc;
      }, emptyClubScores
      ))
      .map(([club, data]) => {
        return { id: club, club, ...data };
      });

    const categoryName = this.props.categoryDisplayNames[this.category] || lodash.startCase(this.category);
    const archetype = this.props.categoryArchetype;
    const title = `${archetype} ${categoryName} Standings`;

    this.handlePrint = () => {
      this.doPrintWithContext(title, columns, data, {
        keyboard: true
      });
    };

    this.scoreSheetHandlers = {
      Print: this.handlePrint.bind(this)
    };

    return (
      <GlobalHotKeys keyMap={this.scoreSheetKeyMap} handlers={this.scoreSheetHandlers}>
        <Box className={styles.scoreSheet}>
          <Typography sx={{ 
            marginTop: '8px', 
            marginLeft: '8px',
            minWidth: 'calc(100vw - 16px)',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',  
          }} variant="h6">{title}</Typography>
          <Box>
            <DataGrid
              rows={data}
              columns={columns}
              autoPageSize
            />
          </Box>
          <Box sx={{ marginLeft: '8px', height: '4em', display: 'flex', alignItems: 'center' }}>
            <Button sx={{ height: '4em' }} onClick={handleShowCompleteStandings}>Complete Standings</Button>
            <Button sx={{ height: '4em' }} onClick={this.handlePrint}>Print</Button>
          </Box>
        </Box>
      </GlobalHotKeys>
    );
  }

  renderContent() {
    if (this.props.initializing) {
      return this.renderPleaseStandBy();
    } else if (!this.props.ready || this.filteredImages.length === 0) {
      return this.renderNoResultsContent();
    } else if (this.props.view === 'graded') {
      return this.renderScoreSheet();
    } else if (this.props.view === 'standings') {
      return this.renderStandings();
    }
    return this.renderSwiper();
  }

  render() {
    const content = this.renderContent();
    const hasStandings = this.props.eventType === 'honors-competition';

    const handleChange = (e) => {
      this.props.switchToView(e.currentTarget.value);
    };

    const categoryName = this.props.categoryDisplayNames[this.category] || lodash.startCase(this.category);

    const info = `${this.props.campaignName} ${this.props.categoryArchetype} ${categoryName}`;

    return (
      <div className={styles.showcase}>
        <Header></Header>
        {content}
        <Footer info={info} showMoreIcon={false} onPrint={this.handlePrint}>
          <ToggleButtonGroup orientation="horizontal" value={this.props.view} exclusive onChange={handleChange}>
            {hasStandings && (<ToggleButton value="standings" title="standings">
              <SportsScoreIcon />
            </ToggleButton>)}
            <ToggleButton value="graded" title="graded">
              <GradingOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="carousel" title="carousel">
              <CarouselViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Footer>
      </div>
    );
  }
}


WinnersShowcaseView.propTypes = {
  initializing: PropTypes.bool,
  ready: PropTypes.bool,
  showScoreSheet: PropTypes.bool,
  showStandings: PropTypes.bool,
  defaultCategory: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  bestInShow: PropTypes.string,
  specialAwardTitle: PropTypes.string,
  specialAwardCategory: PropTypes.string,
  getImageInfo: PropTypes.func,
  switchToView: PropTypes.func,
  placesNames: PropTypes.arrayOf(PropTypes.string),
  categoryDisplayNames: PropTypes.object,
  view: PropTypes.oneOf(['carousel', 'graded', 'standings']),
  eventType: PropTypes.string,
  eventFeatures: PropTypes.arrayOf(PropTypes.string),
  totalHonors: PropTypes.number,
  honors: PropTypes.object,
  awardMap: PropTypes.objectOf(PropTypes.string),
  awards: PropTypes.objectOf(PropTypes.string),
  scores: PropTypes.object,
  categoryArchetype: PropTypes.string,
  getAwardById: PropTypes.func,
  openCompleteStandings: PropTypes.func,
  openDirectory: PropTypes.func,
  initiativeId: PropTypes.string,
  clubs: PropTypes.array,
  campaignName: PropTypes.string,
};

WinnersShowcaseView.defaultProps = {
  initializing: true,
  ready: false,
  showScoreSheet: false,
  showStandings: false,
  bestInShow: '',
  specialAwardTitle: 'Best in Show',
  specialAwardCategory: null,
  defaultCategory: 'color',
  categories: ['color', 'monochrome'],
  view: 'carousel',
  categoryDisplayNames: {},
  awards: {},
  awardMap: {},
  scores: {},
  honors: {},
  placesNames: [],
  getImageInfo: () => { },
  getAwardById: () => { },
  switchToView: () => { },
  openCompleteStandings: () => { },
  openDirectory: () => { },
  eventType: 'placed-competition',
  eventFeatures: [],
  totalHonors: 0,
  categoryArchetype: 'Category',
  initiativeId: null,
  clubs: [],
  campaignName: null,
};

WinnersShowcaseView.connector = (state) => {
  const bestInShow = state.liveState.gamePlay.bestInShow;
  const initializing = !state.appState.ready;
  const view = state.appState.view;
  const clubs = state.appState.config.clubs;
  const ready = state.liveState.gamePlay.state === 'ended';

  const scores = state.liveState.gamePlay.scores;
  const awards = state.liveState.gamePlay.awards;
  const honors = state.liveState.gamePlay.honors;
  const campaign = state.appState.config.campaigns[state.liveState.campaign];
  const phantomCategory = campaign.phantomCategory;
  const campaignName = campaign.name;
  const initiativeId = campaign.initiativeId;
  const categoryDisplayNames = campaign.categoryDisplayNames;
  const specialAwardTitle = campaign.specialAwardTitle;
  const specialAwardCategory = campaign.specialAwardCategory;
  const placesNames = campaign.liveEvent.placesLongnames;

  const eventType = campaign.liveEvent["event-type"];
  const eventFeatures = campaign.liveEvent["event-features"] || [];

  let categories = campaign.categories;

  if (phantomCategory && eventFeatures.includes('fold-all-categories')) {
    categories = [phantomCategory];
  }

  const totalHonors = campaign.liveEvent.totalHonors;
  const awardMap = campaign.liveEvent.awardMap || {};
  const categoryArchetype = campaign.categoryArchetype;
  const defaultCategory = categories.slice().pop();

  const getImageInfo = (id) => {
    return state.images[id];
  };

  const getAwardById = (id) => {
    return awards[id];
  };

  return {
    initializing,
    ready,
    view: ['carousel', 'graded', 'standings'].includes(view) ? view : 'carousel',
    scores,
    awards,
    honors,
    getImageInfo,
    categoryDisplayNames,
    placesNames,
    categories,
    defaultCategory,
    bestInShow,
    specialAwardTitle,
    specialAwardCategory,
    eventType,
    eventFeatures,
    totalHonors,
    categoryArchetype,
    awardMap,
    getAwardById,
    initiativeId,
    clubs,
    campaignName,
  };
};

WinnersShowcaseView.commander = (dispatch) => {
  return {
    switchToView: (view) => dispatch({ type: actions.SET_ACTIVE_VIEW, payload: { view } }),
    openCompleteStandings: (id) => dispatch({ type: actions.NAVIGATE, payload: { location: `/standings/${id}`, method: 'push' } }),
    openDirectory: (id) => dispatch({ type: actions.NAVIGATE, payload: { location: `/directory/${id}`, method: 'push' } }),
  };
};


export default connect(
  WinnersShowcaseView.connector,
  WinnersShowcaseView.commander
)(WinnersShowcaseView);
