import { Component } from 'react';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Logo from '../../Components/Logo';
import styles from './Header.module.scss';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Cyclone Image Scoring System',
    };
  }

  render() {
    return (
      <AppBar position="static">
        <Toolbar variant="dense" style={{ backgroundColor: 'black' }}>
          <Typography variant="h6" color="inherit" component="div" style={{
            maxWidth: 'calc(100% - 60px',
            textAlign: 'left',
            paddingTop: '5px',
            paddingBottom: '5px'
          }}>
            {this.state.title}
          </Typography>
          <a className={styles.logo} href="https://darkroomers.com" target="_blank" rel="noreferrer" >
            <Logo variant="dark" className={styles.logoIcon} />
            <Logo variant="chip" className={styles.chipIcon} />
          </a>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  campaign: PropTypes.object,
};

Header.defaultProps = {
  campaign: null,
};
