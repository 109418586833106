export function makeHotKeyHandler(keyMap, handlers) {
  return (e) => {
    if (!e.code) {
      return;
    }
    const handlerName = Object.entries(keyMap).reduce((acc, [key, value]) => {
      if (value === e.code) {
        acc = key;
      }
      return acc;
    }, '');
    if (handlerName && handlers[handlerName]) {
      handlers[handlerName].call();
    }
  };
}
