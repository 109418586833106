import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function FullScreenImpl(props) {
    const myHandle = useFullScreenHandle();

    const reportChange = useCallback((state, handle) => {
        if (handle === myHandle) {
            props.onChange(state);
        } else {
            console.log('full screen notification on unknown handle');
        }
    }, [props, myHandle]);

    const buttonStyles = {
        zIndex: 999,
        ...props.buttonStyles
    };

    const enterFullscreenButton = (
        <Button sx={buttonStyles} onClick={myHandle.enter}>Start Full Screen</Button>
    );

    return (
        <FullScreen handle={myHandle} onChange={reportChange}>
            <React.Fragment>
                {props.children}
                {!myHandle.active ? enterFullscreenButton : ''}
            </React.Fragment>
        </FullScreen>
    );

}

FullScreenImpl.propTypes = {
    onChange: PropTypes.func,
    buttonStyles: PropTypes.object,
};

FullScreenImpl.defaultProps = {
    onChange: () => { },
};
