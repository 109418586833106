import * as actions from '../actions';

const DEFAULT_STATE = {};

function initializeImageList(state, images) {
  return images.reduce((acc, image) => {
    acc[image.id] = {
      ...image,
    };
    return acc;
  }, {
    ...DEFAULT_STATE,
  });
}


export default function images(state = DEFAULT_STATE, action) {
  switch (action.type) {

    case actions.INIT_IMAGE_LIST:
      return initializeImageList(state, action.payload.images);
  
    default:
      break;                          
  }

  return state;
}
