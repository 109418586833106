import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Router } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

import LengthyOperationDialog from '../../Dialogs/LengthyOperationDialog';
import LiveView from '../LiveView';
import LiveViewConsole from '../LiveViewConsole';
import Scanner from '../Scanner';
import ScorePad from '../ScorePad';
import ScoreBox from '../ScoreBox';
import Ceremony from '../Ceremony';
import GalleryCatalogView from '../GalleryCatalogView';
import WinnersShowcaseView from '../WinnersShowcaseView';
import ImageReviewView from '../ImageReviewView';
import LeaderboardView from '../LeaderboardView';

import AppController from '../../Controllers/AppController';
import styles from './Workspace.module.scss';

class Workspace extends Component {
  constructor(props) {
    super(props);
    this.history = createHistory(this.props);
    AppController.history = this.history;
  }

  render() {
    const extraClasses = this.history.location.pathname.split('/').map(path => styles[path]).filter(x => x);
    const classes = [styles.workspace, ...extraClasses];
    const className = clsx.apply(null, classes);
    return (
      <div className={className}>
        <Router history={this.history} forceRefresh={false}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/live/current" replace={true} />
            </Route>
            <Route exact path="/live">
              <Redirect to="/live/current" replace={true} />
            </Route>
            <Route exact path="/live/:id" component={LiveView} />
            <Route exact path="/console">
              <Redirect to="/console/current" replace={true} />
            </Route>
            <Route path="/console/:campaignId" component={LiveViewConsole} />
            <Route exact path="/scanner/:id" component={Scanner} />
            <Route exact path="/scanner">
              <Redirect to="/scanner/current" replace={true} />
            </Route>
            <Route exact path="/scorepad/:id" component={ScorePad} />
            <Route exact path="/scorepad">
              <Redirect to="/scorepad/current" replace={true} />
            </Route>
            <Route exact path="/gallery">
              <Redirect to="/gallery/current" replace={true} />
            </Route>
            <Route path="/gallery/:campaignId/:category" component={WinnersShowcaseView} />
            <Route path="/directory/:id" component={GalleryCatalogView} />
            <Route exact path="/directory">
              <Redirect to="/directory/current" replace={true} />
            </Route>

            <Route exact path="/review/:id" component={ImageReviewView} />
            <Route exact path="/review">
              <Redirect to="/review/current" replace={true} />
            </Route>

            <Route exact path="/standings/:id" component={LeaderboardView} />

            <Route exact path="/scorebox/:id" component={ScoreBox} />
            <Route exact path="/scorebox">
              <Redirect to="/scorebox/current" replace={true} />
            </Route>
            <Route exact path="/ceremony/:id" component={Ceremony} />
            <Route exact path="/ceremony">
              <Redirect to="/ceremony/current" replace={true} />
            </Route>
            <Route>
              <Redirect to="/" replace={true} />
            </Route>
          </Switch>
        </Router>
        <LengthyOperationDialog />
      </div >
    );
  }
}

Workspace.propTypes = {
  view: PropTypes.string,
};

Workspace.defaultProps = {
  view: 'grid',
};

Workspace.connector = (state) => {
  return {
    view: state.appState.view,
  };
};

export default connect(
  Workspace.connector
)(Workspace);
