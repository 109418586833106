import lodash from 'lodash';
import * as actions from '../actions';
import constants from '../../constants';

import { isLiveEnabled } from '../getters';
import { isToday, isBeforeDate } from '../../Utils/DateUtils';

const DEFAULT_CATEGORIES = [
  'monochrome',
  'color',
];

export const VIEWS = [
  'carousel',
  'graded',
  'standings',
  'condensed',
  'expanded'
];


const DEFAULT_CAMPAIGN = {
  id: constants.DEFAULT_CAMPAIGN,
  name: 'Competition to end all competitions',
  title: 'Darkroomers Final Competition',
  description: 'You cannot enter. All your image belong to us',
  rules: 'http://norules.com',
  schema: '/nope.json',
  maxEntriesPerUser: 0,
  minTitleLength: 1,
  startDate: 'December 1, 1970',
  endDate: 'December 31, 1970',
  categories: DEFAULT_CATEGORIES,
  votingStartDate: 'December 2, 1970',
  votingEndDate: 'December 31, 2099',
  votingInstructions: 'Vote for up to 3 color and 3 monochrome image. You may change your votes up to the voting end date by returning to this site. The theme for the competition is Explosions...',
  liveEvent: {
    id: '12345678',
    totalJudges: 1,
    minScore: 18,
    maxScore: 27
  },
  isDefault: true,
};

export const DUMMY = {
  avatar: '/avatars/dk.png',
  email: 'info@darkroomers.com',
  firstName: 'Darkroomers',
  id: 'Dummy',
  lastName: 'Dummy',
  scopes: 'none',
  userId: 'Dummy',
  userName: 'Dummy',
};

const DEFAULT_STATE = {
  connectionStatus: {
    status: 'disconnected',
    serviceEndPoint: constants.API_ENDPOINT,
  },

  view: VIEWS[0],

  config: {
    campaigns: {
      [constants.DEFAULT_CAMPAIGN]: DEFAULT_CAMPAIGN
    },
    allCampaigns: {
      [constants.DEFAULT_CAMPAIGN]: DEFAULT_CAMPAIGN
    },
    clubs: [],
    defaultCampaign: constants.DEFAULT_CAMPAIGN,
    currentCampaign: constants.DEFAULT_CAMPAIGN,
    galleryCampaigns: [],
  },
  ready: false,
  "images.ready": false,
  user: DUMMY,
  clientId: 'cyclone-dev',
  loginURI: '/login',
  logoutURI: '/logout',
};

function updateImagesReadyState(state, imagesReady = true) {
  const newState = {
    ...state,
    "images.ready": imagesReady,
  };
  return newState;
}

function updateReadyState(state, ready = true) {
  const newState = {
    ...state,
    ready,
  };
  return newState;
}

function updateServerConfiguration(state, config) {
  const newState = { ...state };
  newState.config.currentCampaign = config.currentCampaign || constants.DEFAULT_CAMPAIGN;
  newState.config.defaultCampaign = config.currentCampaign || constants.DEFAULT_CAMPAIGN;

  newState.config.campaigns = {
    ...lodash.filter(config.campaigns, isLiveEnabled).reduce((acc, campaign) => {
      acc[campaign.id] = campaign;
      return acc;
    }, {}),
    [constants.DEFAULT_CAMPAIGN]: DEFAULT_CAMPAIGN
  };

  newState.config.allCampaigns = config.allCampaigns;

  const eventDateSort = (a, b) => {
    const keyA = `config.campaigns[${a}].liveEvent`;
    const keyB = `config.campaigns[${b}].liveEvent`;
    const dataA = lodash.get(newState, `${keyA}.eventDate`, 0);
    const dataB = lodash.get(newState, `${keyB}.eventDate`, 0);
    const dateA = new Date(dataA);
    const dateB = new Date(dataB);
    return dateB - dateA;
  };

  // const candidates = 
  //   Object
  //     .keys(newState.config.campaigns)
  //     .filter(id => newState.config.campaigns[id].liveEvent.eventDate)
  //     .filter(id => !isAfterDate(newState.config.campaigns[id].liveEvent.eventDate))
  //     .sort(eventDateSort)
  //     .reverse();

  // const campaign = newState.config.campaigns[candidates[0]];

  const campaign = lodash.find(newState.config.campaigns, (campaign) => campaign.liveEvent.eventDate && isToday(campaign.liveEvent.eventDate)) ||
    lodash.find(newState.config.campaigns, (campaign) => campaign.liveEvent.eventDate && isBeforeDate(campaign.liveEvent.eventDate));

  newState.config.galleryCampaigns = Object.values(newState.config.campaigns)
    .map(campaign => campaign.id)
    .sort(eventDateSort);

  newState.config.currentCampaign = (campaign && campaign.id) || lodash.get(newState.config.galleryCampaigns, '0');
  newState.config.imageServiceProvider = config.imageServiceProvider;

  newState.config.clientId = config.clientId;
  newState.config.loginURI = config.loginURI;
  newState.config.logoutURI = config.logoutURI;
  newState.config.proxy = config.proxy;
  newState.config.clubs = config.clubs;
  return newState;
}

function setActiveCampaign(state, campaign) {
  const newState = { ...state };
  newState.config.currentCampaign = campaign;
  return newState;
}

function setActiveView(state, view) {
  if (VIEWS.includes(view)) {
    return {
      ...state,
      view,
    };

  }
  return state;
}

function updateConnectionStatus(state, status) {
  const newState = { ...state };
  newState.connectionStatus = {
    ...state.connectionStatus,
    ...status
  };
  return newState;
}

function updateUser(state, user) {
  const newState = {
    ...state,
    user,
  };
  return newState;
}

function updateUiState(state, throttled) {
  const newState = {
    ...state,
    throttled,
  };
  return newState;
}

const appState = (state = DEFAULT_STATE, action) => {
  let newState;

  switch (action.type) {

    case actions.SET_ACTIVE_CAMPAIGN:
      newState = setActiveCampaign(state, action.payload.campaign);
      break;

    case actions.SET_ACTIVE_VIEW:
      newState = setActiveView(state, action.payload.view);
      break;

    case actions.UPDATE_HEALTH:
      newState = updateConnectionStatus(state, action.payload);
      break;

    case actions.SERVER_CONFIG:
      newState = updateServerConfiguration(state, action.payload.config);
      break;

    case actions.UPDATE_READY_STATE:
      newState = updateReadyState(state, lodash.get(action, 'payload.ready', true));
      break;

    case actions.UPDATE_IMAGES_READY_STATE:
      newState = updateImagesReadyState(state, lodash.get(action, 'payload.images.ready', true));
      break;

    case actions.UPDATE_USER:
      newState = updateUser(state, action.payload.user);
      break;


    case actions.THROTTLE_UI_STATE:
      newState = updateUiState(state, action.payload.throttled);
      break;

    default:
      return state;
  }


  return newState;
};

export default appState;
