import { combineReducers } from 'redux';

import appState from './app-state';
import images from './images';
import liveState from './live-state';
import standings from './standings';
import ceremonyExtras from './ceremony-extras';

export default combineReducers({
  appState,
  images,
  liveState,
  standings,
  ceremonyExtras
});
