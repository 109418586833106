import * as actions from '../actions';

const DEFAULT_STATE = [];

function initializeCeremonyExtrasList(images) {
  return images.map(image => {
    if (image.maker) {
      return image;
    }
    return {
        ...image,
        maker: image.user.name
    };
  });
}


export default function ceremonyExtras(state = DEFAULT_STATE, action) {
  switch (action.type) {

    case actions.INIT_CEREMONY_EXTRAS_LIST:
      return initializeCeremonyExtrasList(action.payload.images);
  
    default:
      break;                          
  }

  return state;
}
