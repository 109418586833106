import mustache from "mustache";
import appController from "../Controllers/AppController";
const report = `
    <!doctype html>
    <html>

    <head>
        <meta charset="utf-8">
        <title>{{title}}</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <style>
        
            @page{ 
                size: auto; 
                margin: 1inch; 
            }

            body {
                font-family: "Roboto";
                font-weight: 100;
            }

            h1 {
                margin: auto;
                padding: 20px;
                text-align: center;
            }

            div.grid {
                display: grid;
                grid-column-gap: 10px;
                margin: auto;
            }

            div.column-header {
                font-weight: 800;
                border-bottom: 1px solid black;
                border-top: 1px solid black;
                padding: 10px;
            }

            div.grid-cell {
                padding: 10px;
                border-bottom: 1px solid lightgray;
            }

            footer {
                text-align: center;
                font-size: 8px;
            }

            @media screen {
                footer {
                  display: none;
                }
              }
              @media print {
                footer {
                  position: fixed;
                  bottom: 0;
                }
              }

              .report {
                max-height: calc(100% - 4em);
              }

        </style>
    </head>

    <body>
        <div class="report">
            <div class="header">
                <h1 style="{{#container-width}}{{/container-width}}">{{title}}</h1>
            </div>
            <div class="grid" style="{{#col-styler}}{{/col-styler}}">
                {{#columns}}
                    {{#col-header}}{{/col-header}}
                {{/columns}}
                {{#rows}}
                    {{#row-renderer}}{{/row-renderer}}
                {{/rows}}
            </div>
        </div>
        <footer>
              {{{footer}}}
        </footer>
    </html>
`;



export function openGridPrintView(title, columns, rows, options) {
    const dom = mustache.render(report, {
        "container-width": () => {

            return (text, render) => {
                const max = columns.reduce((prev, current) => {
                    return prev + current.width;
                }, 0) + (columns.length * 10);
                
                const style = `max-width: ${max}px;`;
                return style;
            };
        },
        "col-styler": () => {

            return (text, render) => {
                const max = columns.reduce((prev, current) => {
                    return prev + current.width;
                }, 0) + (columns.length * 10);
                const cols = columns
                .map((column) => {
                    return `${column.width}px`;
                })
                .join(" ");

                const style = `grid-template-columns: ${cols}; max-width: ${max}px;`;
                return style;
            };
        },
        "col-header": () => {
            return function (text, render) {
                const width = render("{{width}}");
                const headerName = render("{{headerName}}");
                const type = render("{{type}}");
                let align = "";
                if (type === "number") {
                    align = "text-align: right";
                }
                
                if (this.renderHeaderHtml) {
                    return `
                        <div class="column-header" style="width: ${width}px;${align}">${this.renderHeaderHtml(this)}</div>
                    `;
                }
                return `
                    <div class="column-header" style="width: ${width}px;${align}">${headerName}</div>
                `;
            };
        },
        "row-renderer": () => {
            return function (text, render) {
                const row = this;
                function columnMapper(column) {
                let fieldVal = render(`{{${column.field}}}`);
                if (column.renderHtml) {
                    fieldVal = column.renderHtml(
                        {
                            value: row[column.field],
                            row
                        }
                    );
                } else if (column.renderCell) {
                    fieldVal = column.renderCell(
                        {
                            value: row[column.field],
                            row
                        }
                    );
                }
                let align = "";
                if (column.type === "number") {
                    align = "text-align: right";
                }
                return `<div class="grid-cell" style="width: ${column.width}px;${align}">
                    ${fieldVal}
                </div>`;
                }
                return columns.map(columnMapper).join("\r");
            };
        },
        title,
        rows,
        columns,
        footer: (options ? options.footer : null) || `Copyright © ${new Date().getFullYear()} Darkroomers Photographic Club, All Rights Reserved. <b>https://darkroomers.com</b>`
    });

    const blob = new Blob([dom.trim()], { type: "text/html" });
    appController.openWindow(blob, options);
}
