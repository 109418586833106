import React, { Component } from 'react';
import PropTypes from 'prop-types';

import lodash from 'lodash';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { DataGrid } from '@mui/x-data-grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AppController from '../../../Controllers/AppController';
import DialogController from '../../../Controllers/DialogController';

import LiveViewConsoleProps from '../LiveViewConsoleProps';

const lowerTThirdColorOpacity = "0.9";

export default class EventActions extends Component {

  constructor(props) {
    super(props);

    this.renderImageCard = this.renderImageCard.bind(this);
    this.reviewImages = this.reviewImages.bind(this);
  }

  reviewImages() {
    const url = new URL(window.location.href);
    url.pathname = `/review/${this.props.match.params.campaignId}`;
    window.open(url, '_blank');
  }

  renderImageCard(id, index) {
    const colors = [
      { background: `rgba(200, 120, 50, ${lowerTThirdColorOpacity})`, foreground: 'black' },
      { background: `rgba(120, 0, 120, ${lowerTThirdColorOpacity})`, foreground: 'black' },
      { background: `rgba(20, 200, 200, ${lowerTThirdColorOpacity})`, foreground: 'black' },
      { background: `rgba(0, 0, 200, ${lowerTThirdColorOpacity})`, foreground: 'white' },
      { background: `rgba(0, 120, 20, ${lowerTThirdColorOpacity})`, foreground: 'black' },
      { background: `rgba(20, 220, 120, ${lowerTThirdColorOpacity})`, foreground: 'black' },
      { background: `rgba(210, 220, 120, ${lowerTThirdColorOpacity})`, foreground: 'black' },
    ];
    const image = this.props.getImageInfo(id);
    const score = this.props.getScoreById(id);
    const cardStyles = {
      maxWidth: 428,
      minWidth: 428,
      maxHeight: 100,
      minHeight: 100,
      cursor: 'pointer',
      ...colors[index % colors.length],
    };

    return (
      <Grid sx={{
        maxWidth: '345px',
      }}
        item
        key={`image-${index}`}
        xs={6}
      >
        <Card key={`image-${index}-card`} sx={cardStyles}>
          <CardHeader
            key={`image-${index}-header`}
            avatar={(
              <Typography variant="h2">
                {this.props.getPrintNumberById(id)}
              </Typography>
            )}
            title={image ? image.title : ''}
            subheader={`Score: ${score}`}
          />
        </Card>
      </Grid>
    );
  }

  renderImageList() {
    const cards = this.props.imagesToScore.map(this.renderImageCard);
    const boxStylesObj = {
      position: 'relative',
      width: '100%',
      overflow: 'auto',
      height: '100%',
    };
    const gridStylesObj = {
      flexDirection: 'column',
    };
    return (
      <Box key="imageList" sx={boxStylesObj}>
        <Grid key="imageListGrid" sx={gridStylesObj}
          container
          rowSpacing={4}
          columnSpacing={8}
        >
          {cards}
        </Grid>
      </Box>
    );
  }

  renderHonorAwardsAsDatagrid() {

    const columns = [{
      field: 'award',
      headerName: 'Honor',
      width: 80,
      type: 'string',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      type: 'string',
    },
    {
      field: 'score',
      headerName: 'Final Score',
      width: 100,
      type: 'number',
      renderCell: (params) => {
        return params.value.toString();
      },
    },];
    return this.props.categories.map((category) => {
      const rows = Object.keys(this.props.awards).filter(id => {
        const image = this.props.getImageInfo(id);
        return image && image.category === category;
      }).map(id => {
        const image = this.props.getImageInfo(id);
        const score = this.props.scores[id];
        const award = this.props.getAwardById(id);
        return {
          id,
          image,
          score,
          award,
        };
      }).sort((a, b) => {
        return (
          b.score - a.score
        );
      }).map(({ id, image, score, award }, index) => {
        return ({
          id,
          title: image ? image.title : '',
          award,
          score,
        });
      });
      const name = this.props.categoryDisplayNames[category] || category;
      return (
        <Accordion key={`${name}-accordion`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="leaderboard"
            id={name}
          >
            <Typography variant="h5">{name}</Typography>
          </AccordionSummary>
          <AccordionDetails key={`${name}-details`}>
            <Box sx={{ height: '400px', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                autoPageSize
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  renderTopHonorsAsDatagrid() {
    const honors = this.props.totalHonors;
    const columns = [{
      field: 'title',
      headerName: 'Title',
      width: 250,
      type: 'string',
    },
    {
      field: 'score',
      headerName: 'Final Score',
      width: 100,
      type: 'number',
      renderCell: (params) => {
        return params.value.toString();
      },
    },];
    return this.props.categories.map((category) => {
      const rows = Object.keys(this.props.scores).filter(id => {
        const image = this.props.getImageInfo(id);
        return image && image.category === category;
      }).map(id => {
        const image = this.props.getImageInfo(id);
        const score = this.props.scores[id];
        return {
          id,
          image,
          score
        };
      }).sort((a, b) => {
        return (
          b.score - a.score
        );
      }).slice(0, honors)
        .map(({ image, score }, index) => {
          return ({
            id: image.id,
            title: image ? image.title : '',
            score,
          });
        });
      const name = this.props.categoryDisplayNames[category] || category;
      return (
        <Accordion key={`${name}-accordion`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="leaderboard"
            id={name}
          >
            <Typography variant="h5">{name}</Typography>
          </AccordionSummary>
          <AccordionDetails key={`${name}-details`}>
            <Box sx={{ height: '400px', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                autoPageSize
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  renderFinalResultsAsDatagrid() {
    const rowsToShow = 
      this.props.eventType === 'print-competition' ? this.props.placesNames.length : undefined;
    const columns = [{
      field: 'place',
      headerName: 'Place',
      width: 76,
      type: 'string',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 250,
      type: 'string',
    },
    {
      field: 'score',
      headerName: 'Final Score',
      width: 100,
      type: 'number',
      renderCell: (params) => {
        return params.value.toString();
      },
    },];
    return this.props.categories.map((category) => {
      const rows = Object.keys(this.props.scores).filter(id => {
        if (this.props.eventFeatures.includes('fold-all-categories')) {
          // folded categories means that we combine everything into one category
          return true;
        }
        const image = this.props.getImageInfo(id);
        return image && image.category === category;
      }).map(id => {
        const image = this.props.getImageInfo(id);
        const score = this.props.scores[id];
        return {
          id,
          image,
          score
        };
      }).sort((a, b) => {
        return (
          b.score - a.score
        );
      }).slice(0, rowsToShow)
        .map(({ image, score }, index) => {
          const place = this.props.getAwardById(image.id) || this.props.shortPlacesNames[index];
          const title = image ? image.title : '';
          return ({
            id: image.id,
            title,
            place,
            score,
          });
        });

      const folded = this.props.eventFeatures.includes('fold-all-categories');
      const name = folded ? 'Images' : (this.props.categoryDisplayNames[category] || category);
      
      const includeBest = (this.props.bestInShow && 
          this.props.getImageInfo(this.props.bestInShow) &&
          this.props.specialAwardCategory === this.props.getImageInfo(this.props.bestInShow).category  &&
          this.props.eventFeatures.includes('best-in-show-is-special-award') &&
          this.props.eventFeatures.includes('special-award'));
      
      if (includeBest) {
        const bestImage = this.props.getImageInfo(this.props.bestInShow);
        rows.unshift({
          id: bestImage.id,
          title: bestImage.title,
          place: lodash.startCase(this.props.specialAwardCategory),
          score: '--',
        });
      }


      return (
        <Accordion key={`${name}-accordion`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="leaderboard"
            id={name}
          >
            <Typography variant="h5">{name}</Typography>
          </AccordionSummary>
          <AccordionDetails key={`${name}-details`}>
            <Box sx={{ height: '400px', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                autoPageSize
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  renderCurrentRoundLeaderboardAsDatagrid() {
    const handleImageActions = (id) => {
      const score = this.props.getScoreById(id);
      const canRescore = this.props.scoringState === 'disabled' && this.props.eventState === 'running';

      if (canRescore
        && score > 0
        && !this.props.imagesToScore.some(imageId => imageId === id)) {
        const image = this.props.getImageInfo(id);
        DialogController.doAlert(`${image.title} by ${image.maker}`, 'Rescore this image?', 'yesNo')
          .then((result) => {
            if (result === 'yes') {
              this.props.rescoreImage(id);
            }
          });
      }
    };


    let columns = [
      {
        field: 'title',
        headerName: 'Title',
        width: 250,
        type: 'string',
      },
      {
        field: 'score',
        headerName: 'Score',
        width: 100,
        type: 'number',
        renderCell: (params) => {
          return (params.value || 0).toString();
        },
      },
    ];

    if (this.props.eventState === 'image-voting') {
      columns.push({
        field: 'votes',
        headerName: 'Votes',
        width: 100,
        type: 'number',
      });
    }

    const actionsColumn = {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (((params.row && this.props.imagesToScore.indexOf(params.row.id) === -1) && (
          <Button onClick={() => handleImageActions(params.row.id)}>Rescore</Button>
        )) || '');
      }
    };

    if (this.props.isOperator
      && this.props.currentCategory
      && this.props.modality === 'printed-image'
      && this.props.scoringState === 'disabled'
      && this.props.eventState === 'running') {
      columns = [
        {
          field: 'printId',
          headerName: 'ID',
          width: 64,
          type: 'number',
        },
        ...columns,
        actionsColumn,
      ];
    } else if (this.props.isOperator
      && this.props.modality === 'projected-image'
      && this.props.scoringState === 'disabled'
      && this.props.eventState === 'running') {
      columns.push(actionsColumn);
    }

    if (this.props.eventState === 'report-winners' && this.props.eventType !== 'top-honors') {
      // TODO: don't do this for `top-honor` competitions...
      columns = [
        {
          field: 'place',
          headerName: this.props.eventType === 'honors-competition' ? 'Honor' : 'Place',
          sortable: true,
          width: 80,
          renderCell: (params) => {
            const award = this.props.getAwardById(params.row.id);
            if (award) {
              return award;
            }
            
            const place = this.props.imagesInRound.indexOf(params.row.id);
            return ((place !== -1) && this.props.shortPlacesNames[place]);
          }
        },
        ...columns,
      ];
    }

    const rows = this.props.imagesInRound.map(id => {
      const image = this.props.getImageInfo(id);
      const score = this.props.getScoreById(id);
      const votes = this.props.getVotesById(id);
      const printId = this.props.getPrintNumberById(id);
      return {
        image,
        score,
        votes,
        printId
      };
    }).sort((a, b) => {
      return (
        (b.score + b.votes) - (a.score + a.votes)
      );
    }).map(({ image, score, printId, votes }, index) => {
      return {
        id: image ? image.id : index,
        printId,
        title: image ? image.title : '',
        score,
        votes,
      };
    });
    return (
      <Box key="currentLeaders" sx={{ height: '400px', width: '100%' }}>
        <DataGrid
          key="currentLeadersGrid"
          rows={rows}
          columns={columns}
          autoPageSize
        />
      </Box>
    );

  }

  renderFinalResults() {
    if (this.props.eventType === 'top-honors') {
      return this.renderTopHonorsAsDatagrid();
    } else if (this.props.eventType === 'honors-competition') {
      return this.renderHonorAwardsAsDatagrid();
    }
    return this.renderFinalResultsAsDatagrid();
  }

  renderLeaderboard() {
    const cardStyles = {
      maxWidth: 400,
      minWidth: 400,
      minHeight: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'blue',
      padding: '20px',
    };
    if (['ended', 'choosing-best'].includes(this.props.eventState)) {
      return (
        <Card style={cardStyles} key="finalResults">
          <Typography sx={{ padding: '20px 0', color: 'white' }} variant="h3">
            Final Results
          </Typography>
          {this.renderFinalResults()}
        </Card>
      );
    } else if (this.props.currentCategory) {
      return (
        <Accordion key="leaders">
          <AccordionSummary
            key="leadersAccordion"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="leaderboard"
            id="leaderboard"
          >
            <Typography variant="h5">Leaderboard</Typography>
          </AccordionSummary>
          <AccordionDetails key="leadersDetail">
            {this.renderCurrentRoundLeaderboardAsDatagrid()}
          </AccordionDetails>
        </Accordion>
      );
    }
  }

  
  render() {

    const folded = this.props.eventFeatures.includes('fold-all-categories');

    const image = this.props.images.slice(-1).pop();

    let action;

    const voteForImage = (upOrDown) => {
      this.props.voteForImage(image.id, upOrDown);
    };

    const handlePrintLabels = () => {
      let base = this.props.proxy || window.location.href;
      AppController.openURL(new URL(this.props.labelInfo.url, base).toString());
    };

    const handlePrintURL = (url) => {
      let base = this.props.proxy || window.location.href;
      AppController.openURL(new URL(url, base).toString());
    };

    const handlePrintReport = (key) => {
      handlePrintURL(this.props.labelInfo[key]);
    };

    let finalScore;

    let choices = "Picking ...";

    if (this.props.eventState === 'pick-winners') {
      if (this.props.eventType === 'placed-competition-with-mentions') {
        const placeIndex = Object.keys(this.props.awards).length;
        const place = this.props.shortPlacesNames[placeIndex];
        const placeName = place.toLowerCase() === 'hm' ? 'Honorable Mention' : `${place} Place`;
        choices = `Choosing ${placeName}`;
      } else {
        const placeIndex = this.props.imagesInRound.indexOf(this.props.images[0].id);
        const places = this.props.imagesToScore.length;
        const placeNames = this.props.shortPlacesNames.slice(placeIndex, placeIndex + places);
        const place = placeNames.shift();
        const placeName = place.toLowerCase() === 'hm' ? 'Honorable Mention' : `${place} Place`;
        choices = `Choosing ${placeName}`;
      }
    } else if (this.props.eventState === 'choosing-best') {
      choices = `Choosing ${this.props.specialAwardTitle}`;
    }

    const actionButtonStyles = {
      height: '3.8em',
      margin: '20px',
      width: 'calc(100% - 1em)',
      fontSize: '3em',
    };

    // TODO: use event-features to determine which buttons to show
    const printLabelsText = this.props.eventType === 'honors-competition' ? 'Print Score Sheets' : 'Print Labels';

    const printLabelsButton = this.props.labelInfo && this.props.labelInfo.url && this.props.isOperator && (
      <Button key="printButton" sx={actionButtonStyles} onClick={handlePrintLabels} variant="contained">
        {printLabelsText}
      </Button>
    );

    const revealSecrets = this.props.labelInfo && this.props.labelInfo.secrets && this.props.isOperator && (
      <Button key="revealSecretsButton" sx={actionButtonStyles} onClick={this.props.revealSecrets} variant="contained">
        Reveal Secrets
      </Button>
    );

    const testScoringDevices = (this.props.totalJudges > 1) && this.props.isOperator && !this.props.canPrep && (
      <Button key="testScoringDevicesButton" sx={actionButtonStyles} onClick={this.props.testScoringDevices} variant="contained">
        Test Scoring Devices
      </Button>
    );

    const cancelTesting = (this.props.totalJudges > 1) && this.props.isOperator && (
      <Button key="cancelTesting" sx={{ ...actionButtonStyles, backgroundColor: 'purple', height: '5em' }} onClick={this.props.testScoringDevices} variant="contained">
        Cancel Scoring Device Test
      </Button>
    );

    const reviewImages = this.props.isOperator && this.props.eventFeatures.includes('image-review') && !this.props.canPrep && (
      <Button key="reviewImages" sx={{ ...actionButtonStyles, backgroundColor: 'red', height: '5em' }} onClick={this.reviewImages} variant="contained">
        Review Images
      </Button>
    );

    // FIXME: condense all of this...
    let vernacular;
    if (this.props.eventType === 'honors-competition') {
      vernacular = 'Final Score Sheets';
    } else if (this.props.eventType === 'top-honors') {
      vernacular = 'Honors Packet';
    } else {
      vernacular = 'Sleeves';
    }
    const key = lodash.kebabCase(vernacular);

    const prepareSleeves = this.props.isOperator && this.props.labelInfo && !this.props.labelInfo[key] && (
      <Button
        key={key}
        sx={{ ...actionButtonStyles, backgroundColor: 'blue', height: '5em' }}
        onClick={() => this.props.prepReport(key)}
        variant="contained"
      >
        Prepare {vernacular}
      </Button>
    );

    const printSleeves = this.props.isOperator && this.props.labelInfo && this.props.labelInfo[key] && (
      <Button key={key} sx={{ ...actionButtonStyles, backgroundColor: 'green', height: '5em' }} onClick={() => handlePrintReport(key)} variant="contained">
        Print {vernacular}
      </Button>
    );

    const currentReportName = this.props.getCurrentMidCycleReportId();
    const reportUrl = this.props.labelInfo && this.props.labelInfo[currentReportName];

    const prepareReport = this.props.isOperator && !reportUrl && (
      <Button key="prepareReport" sx={{ ...actionButtonStyles, backgroundColor: 'purple', height: '5em' }} onClick={() => this.props.prepReport('mid-cycle')} variant="contained">
        Prepare Mid Cycle Report
      </Button>
    );

    const printReport = this.props.isOperator && reportUrl && (
      <Button key="printReport" sx={{ ...actionButtonStyles, backgroundColor: 'green', height: '5em' }} onClick={() => handlePrintURL(reportUrl)} variant="contained">
        Print Mid Cycle Report
      </Button>
    );

    const previewImages = this.props.isOperator && reportUrl && this.props.toGo > 0 && this.props.modality === 'projected-image' && (
      <Button key="previewImages" sx={{ ...actionButtonStyles, backgroundColor: 'purple', height: '5em' }} onClick={() => this.props.previewImagesInRound()} variant="contained">
        Preview Images in This Round
      </Button>
    );

    const cardStyles = {
      maxWidth: 400,
      minWidth: 400,
      minHeight: 100,
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: 'orange',
      padding: '20px',
    };

    const resumeCard = (
      <Card key="resumeGameCard" sx={{ ...cardStyles, backgroundColor: 'red', color: 'white' }}>
        <Typography variant="h4">Waiting for event to resume...</Typography>
      </Card>
    );

    const startMessage = this.props.canPrep ? "Waiting for event to be prepared" : "Waiting for event to start";
    const startContent = <Typography variant="h4">{startMessage}...</Typography>;

    switch (this.props.scoringState) {
      case 'testing':
        action = (
          <Card key="startGameCard" sx={cardStyles}>
            {startContent}
            <Box sx={{ position: 'relative', textAlign: 'center' }}>
              {cancelTesting}
            </Box>
          </Card>
        );
        break;
      case 'disabled':
        if (['upcoming', 'scoring-test'].includes(this.props.eventState)) {
          action = (
            <Card key="startGameCard" sx={cardStyles}>
              {startContent}
              <Box sx={{ position: 'relative', textAlign: 'center' }}>
                {printLabelsButton}
                {revealSecrets}
                {testScoringDevices}
                {reviewImages}
              </Box>
            </Card>
          );
        } else if (this.props.eventState === 'report-winners') {
          const text = folded 
            ? 'Preliminary Results' 
            : `Reporting Winners in the ${this.props.categoryDisplayNames[this.props.currentCategory] || this.props.currentCategory} ${this.props.categoryArchetype}`;
          action = (
            <Card key="reportingWinners" sx={cardStyles}>
              <Typography variant="h4">{text}</Typography>
            </Card>
          );
        } else if (this.props.eventState === 'ended' && (
                    this.props.eventType === 'honors-competition' 
                    || this.props.modality === 'printed-image'
                    || this.props.eventType === 'top-honors')) {
          action = (
            <Card key="reportingWinners" sx={cardStyles}>
              <Typography variant="h4">Competition has ended...</Typography>
              <Box sx={{ position: 'relative', textAlign: 'center' }}>
                {prepareSleeves}
                {printSleeves}
              </Box>
            </Card>
          );
        } else if (this.props.isOperator && this.props.eventState === 'intermission' && this.props.canPrepMidCycleReport) {
          action = (
            <Box>
              {resumeCard}
              <Card key="reportingWinners" sx={cardStyles} >
                <Box sx={{ position: 'relative', textAlign: 'center' }}>
                  {prepareReport}
                  {printReport}
                  {previewImages}
                </Box>
              </Card>
            </Box>
          );
        } else if (this.props.paused) {
          action = resumeCard;
        } else if (this.props.scoringType === 'manual') {
          if (!this.props.currentCategory) {
            action = (
              <Card key="startCategoryCard" sx={cardStyles}>
                <Typography variant="h4">Waiting for {this.props.categoryArchetype} to be selected...</Typography>
              </Card>
            );
          } else {
            action = (
              <Card key="startImageCard" sx={{ ...cardStyles, backgroundColor: 'green' }}>
                <Typography variant="h4">Waiting for image to be presented...</Typography>
              </Card>
            );
          }
        } 
        break;
      case 'picking':
        action = (
          <Card key="pickingCard" sx={cardStyles}>
            <Typography variant="h4">{choices}</Typography>
          </Card>
        );
        break;
      case 'scoring':
        action = this.props.isOperator && !this.props.paused && (
          <Box sx={{ padding: '30px', border: '1px dashed blue', }}>
            <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Box sx={{ marginTop: '1em' }}>
                    <Typography variant="h3">Score</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  {this.props.scoringInput}
                </Grid>
                <Grid item xs={4}>
                  {this.props.scoringButton}
                </Grid>
              </Grid>
            </Box>
          </Box >
        );
        finalScore = image && (
          <Typography variant="h5">
            {this.props.eventState === 'report-winners' ? 'Final ' : 'Current'}&nbsp;Score:&nbsp;{
              this.props.getScoreById(image.id) === 0 ? '--' : this.props.getScoreById(image.id)
            }
          </Typography>
        );
        break;
      case 'voting':
        action = this.props.isOperator && (
          <Box sx={{ padding: '30px', border: '1px dashed blue', }}>
            <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid item xs={6}>
                  <Box sx={{ marginTop: '1em' }}>
                    <Typography variant="h3">Vote</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Button key="upVoteButton" sx={{ height: '3.8em' }} onClick={() => voteForImage('up')} variant="contained">
                    In
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button key="downVoteButton" sx={{ height: '3.8em' }} onClick={() => voteForImage('down')} variant="contained">
                    Out
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box >
        );
        break;
      default:
        break;
    }

    let support;
    let leaders;

    const imagesNeededStates = [
      'pick-winners',
      'choosing-best',
      'intermission',
      'running',
      'scoring-images',
      'elimination'
    ];
    if (this.props.modality === 'printed-image'
      && this.props.currentCategory
      && this.props.isOperator
      && imagesNeededStates.includes(this.props.eventState)) {
      support = (
        <Accordion key="images-needed">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="images-needed"
            id="images-needed"
          >
            <Typography variant="h5">Prints Needed for this Round</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {this.renderImageList()}
          </AccordionDetails>
        </Accordion>
      );
    }

    const upcomingStates = [
      'upcoming',
      'scoring-test'
    ];

    const inPlay = !upcomingStates.includes(this.props.eventState);

    if (inPlay) {
      leaders = this.renderLeaderboard();
    }

    return (
      <Box>
        {finalScore}
        <Box sx={{ marginTop: '20px', marginBottom: '40px', color: 'red' }}>
          {action}
        </Box>
        <Box>
          {support}
          {leaders}
        </Box>
      </Box>
    );
  }
}

EventActions.propTypes = {
    ...LiveViewConsoleProps.propTypes,
    scoringInput: PropTypes.element,
    scoringButton: PropTypes.element,
    images: PropTypes.arrayOf(PropTypes.object),
};

EventActions.defaultProps = {
    ...LiveViewConsoleProps.defaultProps,
    scoringInput: null,
    scoringButton: null,
    images: [],
};
