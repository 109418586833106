import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
import lodash from 'lodash';

import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

import Header from '../Header';
import Footer from '../Footer';

import styles from './GalleryCatalogView.module.scss';
import * as actions from '../../model/actions';

class GalleryCatalogView extends Component {
  constructor(props) {
    super(props);

    this.renderCategoryThumbnailCard = this.renderCategoryThumbnailCard.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info'
    };
  }

  handleCloseSnackbar() {
    this.setState({ openSnackbar: false });
  }

  renderCategoryThumbnailCard(category, index) {
    return (
      <Grid sx={{
        maxWidth: '346px',
      }}
        item
        key={`image-${index}`}
        xs={6}
      >
        <Card sx={{
          maxWidth: 346,
          minWidth: 346,
          maxHeight: 246,
          minHeight: 246,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'dodgerblue',
            '*': {
              color: 'white',
              borderColor: 'white',
            },
          }
        }} onClick={(e) => {
          this.props.openGallery(this.props.match.params.id, category.name);
        }}>
          <CardMedia
            component="img"
            alt={category.name}
            height="200"
            image={category.thumbnail}
          />
          <div className={styles.cardContent}>
            <Typography variant="h6">
              {category.displayName}
            </Typography>
          </div>
        </Card>
      </Grid>
    );
  }

  renderImageGrid() {
    const cards = this.props.categories.map(this.renderCategoryThumbnailCard);

    return (
      <Box sx={{
        position: 'relative',
        width: '100%',
        overflow: 'auto',
        height: `calc(var(--vph, 100vh) - 63px)`,
      }}>
        <Grid sx={{
          marginLeft: '10px',
          marginTop: 0,
          width: 'calc(100% - 16px)',
          paddingBottom: '100px',
          '@media(max-width: 786px)': {
            flexDirection: 'column',
          },
          '& .MuiGrid-item': {
            maxWidth: '375px',
            '@media(max-width: 786px)': {
              paddingLeft: 0,
              paddingRight: 0,
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          }
        }}
          container
          rowSpacing={4}
          columnSpacing={8}
        >
          {cards}
        </Grid>
      </Box>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Sadly, We have nothing to show yet...
        </Typography>
      </div>
    );
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={styles.loader}>
          <RingLoader className={styles.loader} color="white" />
        </div>
      );
    } else if (!this.props.ready || this.props.categories.length === 0) {
      content = this.renderNoResultsContent();
    } else {
      content = this.renderImageGrid();
    }

    return (
      <div>
        <div className={styles.imageCatalogView}>
          <Header></Header>
          {content}
          <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
            <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
          <Footer info={this.props.campaignName}></Footer>
        </div>
      </div>
    );
  }
}

GalleryCatalogView.propTypes = {
  categories: PropTypes.array,
  initializing: PropTypes.bool,
  ready: PropTypes.bool,
  openGallery: PropTypes.func,
  campaignName: PropTypes.string,
};

GalleryCatalogView.defaultProps = {
  categories: [],
  initializing: true,
  ready: false,
  campaignName: null,
  openGallery: () => { },
};

GalleryCatalogView.connector = (state) => {
  const ready = state.liveState.gamePlay.state === 'ended';
  const initializing = !state.appState.ready;
  const scores = state.liveState.gamePlay.scores;
  const honors = state.liveState.gamePlay.honors;
  const bestInShow = state.liveState.gamePlay.bestInShow;
  const campaign = state.appState.config.campaigns[state.liveState.campaign];
  const phantomCategory = campaign.phantomCategory;
  const eventType = campaign.liveEvent["event-type"];
  const eventFeatures = campaign.liveEvent["event-features"] || [];

  const campaignName = campaign.name;
  const categoryArchetype = campaign.categoryArchetype;
  
  let categoryDisplayNames = campaign.categoryDisplayNames
    || campaign.categories.reduce((acc, current) => {
      const categoryDisplayName = lodash.startCase(current);
      acc[current] = categoryDisplayName;
      return acc;
    }, {});

  if (categoryArchetype) {
    categoryDisplayNames = Object.fromEntries(Object.entries(categoryDisplayNames).map(([category, displayName]) => {
      return [category, `${categoryArchetype} ${displayName}`];
    }));
  }


  let categories = campaign.categories;
  const folded = phantomCategory && eventFeatures.includes('fold-all-categories');
  const hasSpecialAward = eventFeatures.includes('special-award');
  const bestInShowIsSpecial = hasSpecialAward && eventFeatures.includes('best-in-show-is-special-award');
  const showcaseBest = folded && bestInShowIsSpecial && eventFeatures.includes('showcase-special-award');
  
  if (folded) {
    categories = [phantomCategory];
  }

  categories = categories.map((category) => {
      if (eventType === 'top-honors') {
        return (honors[category] || [])
          .map((id) => {
            return {
              ...state.images[id],
              score: scores[id],
            };
          })
          .sort((a, b) => {
            return (
              b.score - a.score
            );
          })
          .shift();
      }
      return Object.entries(scores)
        .map(([id, score]) => {
          return {
            ...state.images[id],
            score
          };
        })
        .filter(image => image.category === category || folded)
        .sort((a, b) => {
          return (
            b.score - a.score
          );
        })
        .shift();
    })
    .filter(image => !!image)
    .map((image) => {
      const name = folded ? phantomCategory : image.category;
      const displayName = folded ? 'Winning Images' : categoryDisplayNames[name];
      const thumbnail = (showcaseBest) ? state.images[bestInShow].url : image.url;
      return {
        name,
        displayName,
        thumbnail,
      };
    });

  return {
    ready,
    categories,
    initializing,
    campaignName,
  };
};

GalleryCatalogView.commander = (dispatch) => {
  return {
    openGallery: (id, category) => dispatch({ type: actions.NAVIGATE, payload: { location: `/gallery/${id}/${category}`, method: 'push' } }),
  };
};


export default connect(
  GalleryCatalogView.connector,
  GalleryCatalogView.commander
)(GalleryCatalogView);
