export const isLiveEnabled = (campaign) => campaign && campaign.liveEvent;


export function getCampaignNameById(state, id) {
  return Object.values(state.appState.config.campaigns).reduce((acc, campaign) => {
    if (campaign.id === id) {
      acc = campaign.name;
    }
    return acc;
  }, '');
}

