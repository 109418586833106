import { userHasScope } from "../Utils/UserUtils";
import { DUMMY } from "./reducers/app-state";

export function getCampaigns(state) {
  return state.appState.config.campaigns;
}

export function getCurrentCampaign(state) {
  const campaignId = state.appState.config.currentCampaign;
  return state.appState.config.campaigns[campaignId];
}

export function isAppReady(state) {
  return state.appState.ready;
}

export function hasCurrentEventStarted(state) {
  return !['upcoming', 'test-scoring'].includes(state.liveState.gamePlay.state);
}

export function hasCurrentEventEnded(state) {
  return state.liveState.gamePlay.state === 'ended';
}

export function hasScope(state, scope) {
  return userHasScope(state.appState.user, scope);
}

export function isOperator(state) {
  return hasScope(state, 'cyclone.live.operator');
}

export function isDummyUser(state) {
  return state.appState.user.id === DUMMY.id;
}

export function computeCurrentImageScoringData(state) {
  const totalJudges = state.liveState.totalJudges || 1;

  const {
    currentImageScores,
    currentImageVotes,
    currentImage,
    state: eventState,
  } = state.liveState.gamePlay;

  const wasScoreOverridden = !Array.isArray(currentImageScores);
  const wasVoteVetoed = !Array.isArray(currentImageVotes);

  const scores = !wasScoreOverridden ? currentImageScores.map(score => {
    if (score.abstain) {
      return Number.NaN;
    }
    return score.score;
  }) : [];
  const votes = !wasVoteVetoed ? currentImageVotes.map(vote => {
    if (vote.abstain) {
      return Number.NaN;
    }
    return vote.vote ? 1 : 0;
  }) : [];

  const totalScores = !wasScoreOverridden ? currentImageScores.length : 0;
  const totalVotes = !wasVoteVetoed ? currentImageVotes.length : 0;

  const allScored = totalScores === totalJudges;
  const allVoted = totalVotes === totalJudges;

  const isFinal = eventState === 'image-voting'
    ? wasVoteVetoed || allVoted
    : wasScoreOverridden || allScored;

  const score = state.liveState.gamePlay.scores[currentImage];
  const tally = state.liveState.gamePlay.votes[currentImage];

  const voteCount = allVoted ? tally : 0;
  const voteTally = !wasVoteVetoed ? voteCount : (tally * totalJudges);

  const computedScore = allScored ? score : 0;
  const totalScore = !wasScoreOverridden ? computedScore : 0;

  const combinedScore = Number.isInteger(currentImageScores)
    ? currentImageScores
    : totalScore;

  const compositeScore = scores.reduce((sum, score) => {
    return sum + score;
  }, 0);

  return {
    currentImage,   // image being scored currently
    scores,         // individual scores (empty array if overridden)
    votes,          // individual votes (empty array if vetoed)
    isFinal,        // true if the scoring has completed
    voteTally,      // final vote count
    combinedScore,  // final score
    compositeScore, // current composite score
  };
}
