import React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Interstitial from '../../../Components/Interstitial';

import LiveViewConsoleProps from '../LiveViewConsoleProps';

import { getTestImage } from "../../../TestImages";

import hash from '../hash.gif';
import styles from '../LiveViewConsole.module.scss';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

export default function ImageViewer(props) {

    const lowerThirdOpacity = "0.9";

    let showInterstitial =
      props.initializing
      || props.eventState === 'upcoming'
      || props.eventState === 'scoring-test'
      || props.eventState === 'intermission'
      || props.eventState === 'ended'
      || props.showState === 'interstitial'
      || props.showState.length === 0;

    let imageUrls = [];

    if (!showInterstitial) {
      imageUrls = Array.isArray(props.showState) ? props.showState : [props.showState];
      imageUrls = imageUrls
        .map(props.getImageInfo)
        .filter(image => Boolean(image))
        .sort((a, b) => {
          if ((props.eventState === 'report-winners') && (props.eventType === 'placed-competition-with-mentions')) {
              // we have to sort this based on order of awards, not scores...
              const scoreA = props.getAwardById(a.id);
              const scoreB = props.getAwardById(b.id);
              return scoreA - scoreB;
          } else {
              const scoreA = props.getScoreById(a.id);
              const scoreB = props.getScoreById(b.id);
              return scoreA - scoreB;
            }
        });
    }

    let showBreakBumper = false;

    if (showInterstitial) {
      let text;
      let help;
      if (props.eventState === 'intermission') {
        text = "We are on a break";
        help = "Click the Green Resume button to continue";
        showBreakBumper = true;
      } else if (props.eventState === 'scoring-test') {
        text = "We are testing the judge's scoring devices";
        help = "Click the purple Cancel Scoring Device Test to end tests";
      } else if (props.eventState === 'upcoming' && props.eventDate && !props.isOperator) {
        text = `This event will start on ${props.eventDate}`;
        help = "Waiting for event to begin";
      } else if (props.canPrep && !props.labelInfo && props.eventState === 'upcoming') {
        text = "This event needs a few things before we get started...";
        help = "Click the Green Prep button to prepare the resources needed in order to start this event";
      } else if (props.eventState === 'upcoming') {
        text = "This event has not yet started";
        help = "Click the Green Start button to begin";
      } else if (props.eventState === 'ended') {
        text = "This event has ended";
        help = "Thank you for your service";
      } else if (props.showState === 'interstitial') {
        text = "Please Stand By";
        help = "We are about to get underway";
        showBreakBumper = true;
      } else {
        text = "Please Stand By";
        help = "Click the Green Resume button to continue";
        showBreakBumper = true;
      }

      const noise = (
        <Grid key="imageGrid" item xs={12}>
          <div key="hash" className={styles.imageContainer}>
            {
              props.eventState === 'scoring-test'
                ? (<div key="hashImage" className={styles.image} style={{ backgroundImage: `url(${getTestImage()})` }} />)
                : (<div key="testImage" className={styles.image} style={{ backgroundImage: `url(${hash})` }} />)
            }
            <div key="hashData" className={styles.data}>
              <Typography key="hashText" variant="h3">
                {text}
              </Typography>
              <br />
              <Typography key="helpText" variant="h6">
                {props.isOperator && help}
              </Typography>
            </div>
          </div>
        </Grid>
      );

      return showBreakBumper ? <Interstitial message="Program Break" /> : noise;

    }

    const images = imageUrls.map((image, index) => {
      let action;

      const handleEliminateImage = () => {
        props.eliminateImage(image.id);
      };
      const handleSpotlightImage = () => {
        if (image.id === props.currentSpotlight) {
          props.removeSpotlight();
        } else {
          props.spotlightImage(image.id);
        }
      };

      const handleAwardImage = () => {
        props.awardImage(image.id);
      };

      const handleRescoreImage = () => {
        props.rescoreImage(image.id);
      };

      let color;
      let placeName;
      
      if (props.eventType === 'placed-competition-with-mentions' && props.eventState === 'pick-winners') {
        const placeIndex = Object.keys(props.awards).length;
        const place = props.shortPlacesNames[placeIndex];
        placeName = place.toLowerCase() === 'hm' ? 'Honorable Mention' : `${place} Place`;
      } else {
        placeName = props.placesNames[
          props.imagesInRound.indexOf(imageUrls[0].id)
        ];
      }
      const actionButtons = [];

      if (image.id === props.currentSpotlight) {
        color = 'secondary';
      }

      switch (props.scoringState) {
        case 'disabled':
          if (props.eventState === 'running') {
            if (!props.imagesToScore.some(id => id === image.id)) {
              action = props.isOperator && props.currentCategory && (
                <Button key="rescoreButton" sx={{ height: '3.8em' }} onClick={handleRescoreImage} variant="contained">
                  Rescore
                </Button>
              );
            }
          } else if (props.eventState === 'preview-images') {
            action = props.isOperator && <Button key="spotlightButton" sx={{ marginRight: '20px', height: '3.8em' }} onClick={handleSpotlightImage} variant="contained" color={color}>
              Spotlight
            </Button>;
          }
          break;
        case 'picking':
          if (props.eventState === 'pick-winners') {
            actionButtons.push(
              <Button key="awardButton" sx={{ height: '3.8em' }} onClick={handleAwardImage} variant="contained">
                Award {placeName}
              </Button>
            );
          } else if (props.eventState === 'elimination') {
            actionButtons.push(
              <Button key="eliminateButton" sx={{ height: '3.8em' }} onClick={handleEliminateImage} variant="contained">
                Eliminate
              </Button>
            );
          } else if (props.eventState === 'choosing-best') {
            actionButtons.push(
              <Button key="bestButton" sx={{ height: '3.8em' }} onClick={handleAwardImage} variant="contained">
                Best in Show
              </Button>
            );
          }

          if (props.modality === 'projected-image') {
            actionButtons.push(
              <Button key="spotlightButton" sx={{ marginRight: '20px', height: '3.8em' }} onClick={handleSpotlightImage} variant="contained" color={color}>
                Spotlight
              </Button>
            );
          }

          action = props.isOperator && actionButtons;
          break;
        default:
          break;
      }

      let background;
      const colors = [
        { background: `rgba(100, 120, 50, ${lowerThirdOpacity})`, foreground: 'white' },
        { background: `rgba(120, 0, 120, ${lowerThirdOpacity})`, foreground: 'white' },
        { background: `rgba(20, 200, 200, ${lowerThirdOpacity})`, foreground: 'black' },
        { background: `rgba(0, 0, 200, ${lowerThirdOpacity})`, foreground: 'white' },
        { background: `rgba(0, 120, 20, ${lowerThirdOpacity})`, foreground: 'white' },
        { background: `rgba(20, 220, 120, ${lowerThirdOpacity})`, foreground: 'black' },
        { background: `rgba(210, 220, 120, ${lowerThirdOpacity})`, foreground: 'black' },
      ];

      const score = props.getScoreById(image.id);
      const printId = `Print ${props.getPrintNumberById(image.id)}`;
      const imageNumber = `Image # ${props.getPrintNumberById(image.id) || index + 1}`;
      // const imageIndex = `${index + 1}`;
      let place;

      if (props.eventState === 'report-winners') {
        if (props.eventType === 'honors-competition') {
          const honor = props.getAwardById(image.id);
          if (honor) {
            const honorTitle = props.awardMap[honor] || honor;
            place = `(${honorTitle})`;
          }
        } else if (props.eventType !== 'top-honors') {
          const award = props.getAwardById(image.id);
          if (award) {
            place = `(${props.awardMap[award] || award})`;
          } else {
            const placeIndex = props.imagesInRound.indexOf(image.id);
            place = `(${props.placesNames[placeIndex]})`;
          }
        }
      }

      // TODO: are there any cases where we don't want to show the image bug?
      const showImageBug =
        props.modality === 'printed-image'
        || (props.modality === 'projected-image' && props.eventType === 'honors-competition')
        || (props.modality === 'projected-image' && props.eventState === 'preview-images')
        || (props.scoringState === 'picking');

      return (
        <Item key={image.id} className={styles.imageContainer} sx={{ marginBottom: '20px' }}>
          {showImageBug && (
            <Typography key={`image-bug-${image.id}`} variant="h3" sx={{ position: 'absolute', top: 0, right: 0, backgroundColor: `rgba(120, 200, 0, ${lowerThirdOpacity})`, padding: '20px' }}>
              {props.modality === 'printed-image' ? printId : imageNumber}
            </Typography>
          )}
          <div key="image" className={styles.image} style={{ backgroundImage: `url(${image.url})` }} />
          <div key="lowerThird" className={styles.data} style={{ backgroundColor: colors[index % colors.length].background }}>
            <Typography variant="h5" sx={{ color: colors[index % colors.length].foreground }}>
              {image.title} {props.isOperator ? `by ${image.maker}` : ''} {place} <span style={{ float: 'right' }}>Score {score}</span>
            </Typography>
            <Box sx={{ marginTop: "20px", display: "flex", alignItems: "flex-end", flexDirection: "row-reverse" }}>
              {action}
            </Box>
          </div>
          {background}
        </Item>
      );
    });

    return (
      <Grid key="imagePreviewGrid" className={styles.imageGrid} item>
        {images}
      </Grid>
    );
}

ImageViewer.propTypes = LiveViewConsoleProps.propTypes;
ImageViewer.defaultProps = LiveViewConsoleProps.defaultProps;
