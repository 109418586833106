import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Logo from '../Logo';

import styles from './Interstitial.module.scss';

export default function Interstitial(props) {
    const { message } = props;
    return (
        <Box className={styles.interstitial}>
            <Logo variant="chip" className={styles.logo} />
            <div className={styles.standBy}>{message}</div>
        </Box>
    );
}

Interstitial.propTypes = {
    message: PropTypes.string,
};

Interstitial.defaultProps = {
    message: "Please Stand By",
};
