import * as actions from '../actions';
import constants from '../../constants';

const DEFAULT_STATE = {
  id: `${constants.DEFAULT_CAMPAIGN}-GAME`,
  campaign: constants.DEFAULT_CAMPAIGN,
  maxScore: 27,
  minScore: 18,
  totalJudges: 0,
  scoringSystem: {
    name: "noScore",
    type: "manual"
  },
  scores: {},
  categoriesToScore: ['color', 'monochrome'],
  gamePlay: {
    state: "upcoming",
    paused: false,
    showState: "interstitial",
    scoringState: "disabled",
    scores: {},
    votes: {},
    currentScoringSystem: {
      category: "color",
      currentScoringRound: {
        type: "initial",
        imageCount: 0,
        images: [],
        remaining: 0,
        imagesToScore: [],
      },
    },
  },
};




const liveState = (state = DEFAULT_STATE, action) => {
  let newState;

  switch (action.type) {

    case actions.UPDATE_STATE:
      newState = action.payload;
      break;

    default:
      return state;
  }

  return newState;
};

export default liveState;
