import { parseDate } from 'chrono-node';

export function isDateWithinDateRange(date, startText, endText) {
  let start = new Date();
  let end = new Date();

  if (startText) {
    start = new Date(parseDate(`${startText} 0:00`));
  }
  if (endText) {
    end = new Date(parseDate(`${endText} 23:59`));
  }

  return date >= start && date <= end;
}

export function isTodayWithinDateRange(startText, endText) {
  return isDateWithinDateRange(new Date(), startText, endText);
}

export function isToday(startText) {
  return isDateWithinDateRange(new Date(), startText, startText);
}

export function isBeforeDate(dateText) {
  if (!dateText) {
    return false;
  }
  const date = new Date(parseDate(`${dateText} 0:00`));
  const currentDate = new Date();
  return currentDate < date;
}

export function isAfterDate(dateText) {
  if (!dateText) {
    return false;
  }
  const date = new Date(parseDate(`${dateText} 23:59`));
  const currentDate = new Date();
  return currentDate > date;
}

export function parseYearFromDateText(dateText) {
  const date = new Date(parseDate(`${dateText} 0:00`));
  return date.getFullYear();
}
