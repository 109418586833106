import URI from 'urijs';

const constants = {
  API_ENDPOINT: '/cyclone/api/',
  MAX_ENTRIES: 10,
  DEFAULT_CAMPAIGN: 'THUNDER-AND-LIGHTNING-2000'
};

const CONSTRUCT_CALL = (prefix, name, args) => {
  const paths = [
    prefix,
    name,
    ...args
  ];

  const queries = Array.isArray(paths.slice(-1).pop()) ? paths.pop() : null;
  const result = URI.joinPaths.apply(null, paths).toString();
  if (queries && queries.length) {
    return `${result}?${queries.filter(query => Boolean(query)).join('&')}`;
  }
  return result;
};

const CONCAT_REQUEST = (base, ...args) => {
  const baseArgs = base.split('?');
  
  const baseReq = baseArgs.shift();
  const baseQuery = baseArgs.pop();

  const baseQueries = baseQuery.split(';');

  const paths = [
    baseReq,
    ...args
  ];

  const newQueries = Array.isArray(paths.slice(-1).pop()) ? paths.pop() : null;

  const queries = [
    ...baseQueries,
    ...newQueries
  ];

  const result = URI.joinPaths.apply(null, paths).toString();
  if (queries && queries.length) {
    return `${result}?${queries.filter(query => Boolean(query)).join('&')}`;
  }

  return result;
};

const MAKE_API = (name, ...args) => {
  return CONSTRUCT_CALL(constants.API_ENDPOINT, name, args);
};

const MAKE_REQUEST = (name, ...args) => {
  return CONSTRUCT_CALL('/', name, args);
};

export { MAKE_API, MAKE_REQUEST, CONCAT_REQUEST };
export default constants;