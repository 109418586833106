import React, { Component } from 'react';
import { connect } from 'react-redux';

import lodash from 'lodash';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Interstitial from '../../Components/Interstitial';
import styles from './LiveViewConsole.module.scss';

import LiveViewConsoleProps from './LiveViewConsoleProps';

import Header from '../Header';
import Footer from '../Footer';

import * as actions from '../../model/actions';
import AppController from '../../Controllers/AppController';

import { hasScope } from '../../model/selectors';

import CommandButton from './Components/CommandButton';
import RoundInformation from './Components/RoundInformation';
import ImageViewer from './Components/ImageViewer';
import EventActions from './Components/EventActions';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

class LiveViewConsole extends Component {
  constructor(props) {
    super(props);

    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.focusableElement = React.createRef();

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info',
      score: 0,
      showState: [],
    };
  }

  componentDidMount() {
    this.handleFocus();
    AppController.on(AppController.errorEvent, (snackbarMessage) => {
      this.setState(() => {
        return {
          openSnackbar: true,
          snackbarMessage,
          snackbarSeverity: 'error',
        };
      });
    });
  }


  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.showState) !== JSON.stringify(this.props.showState)) {
      this.setState(() => {
        return {
          score: 0,
        };
      });
    }
    this.handleFocus();
  }

  handleFocus() {
    if (this.focusableElement.current) {
      const input = this.focusableElement.current.querySelector('input');
      if (document.activeElement !== input) {
        input.focus();
      }
    }
  }


  handleCloseSnackbar() {
    this.setState({ openSnackbar: false });
  }

  handleLogout() {
    this.props.logout();
  }

  handleLogin() {
    this.props.login();
  }

  renderPleaseStandBy() {
    return (
      <Interstitial message="Off The Air" />
    );
  }

  renderStartButton() {
    return (
      <CommandButton {...this.props} />
    );
  }

  renderRoundInformation() {
    return (
      <RoundInformation {...this.props} />
    );
  }

  renderCurrentImage() {
    return (
      <ImageViewer {...this.props} />
    );
  }

  renderImageActions() {

    let imageUrls = [];

    let showInterstitial = this.props.initializing
      || this.props.eventState === 'upcoming'
      || this.props.eventState === 'intermission'
      || this.props.showState.length === 0;

    if (!showInterstitial) {
      imageUrls = Array.isArray(this.props.showState) ? this.props.showState : [this.props.showState];
      imageUrls = imageUrls
        .map(this.props.getImageInfo)
        .filter(image => !!image);
    }

    const image = imageUrls.slice(-1).pop();
    const doScore = (score) => {
      if (lodash.clamp(score, this.props.minScore * this.props.totalJudges, this.props.maxScore * this.props.totalJudges) !== score) {
        this.setState(() => {
          return {
            openSnackbar: true,
            snackbarMessage: `Score must be between ${this.props.minScore * this.props.totalJudges} and ${this.props.maxScore * this.props.totalJudges}`,
            score: 0,
          };
        });
      } else {
        this.props.scoreImage(image.id, score);
      }
    };
    const handleScoreImage = () => {
      const input = this.focusableElement.current.querySelector('input');
      const score = parseInt(input.value);
      doScore(score);

    };
    const handleKeyPress = (event) => {
      if (['Enter', 'NumpadEnter'].includes(event.code)) {
        const input = this.focusableElement.current.querySelector('input');
        const score = parseInt(input.value);
        doScore(score);
      }
    };

    const handleChange = (event) => {
      let score = parseInt(event.currentTarget.value);
      if (Number.isNaN(score)) {
        score = 0;
      }
      this.setState({
        score
      });
      if (this.props.maxScore < 10
        && score >= this.props.minScore * this.props.totalJudges
        && score <= this.props.maxScore * this.props.totalJudges
        && this.props.scoringType === 'manual') {
        doScore(score);
      }
    };

    const showScoreButton = this.props.isOperator && (this.props.maxScore > 10 || this.props.scoringType === 'automated');

    const scoringButton = showScoreButton ? (
      <Button key="scoreButton" onClick={handleScoreImage} variant="contained">
        Score Image
      </Button>
    ) : null;

    const scoringInput = (
      <TextField
          sx={{ maxWidth: '4em', }}
          ref={this.focusableElement}
          label="Score"
          onKeyPress={handleKeyPress}
          value={this.state.score}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
    );

    return (
      <EventActions 
        {...this.props} 
        scoringInput={scoringInput}
        scoringButton={scoringButton}
        images={imageUrls}
      />
    );
  }

  renderConsole() {
    return (
      <Box className={styles.consoleGrid}>
        <Item key="startButton" className={styles.startButton}>
          {this.renderStartButton()}
        </Item>
        <Item key="roundInfo" className={styles.roundInformation}>
          {this.renderRoundInformation()}
        </Item>
        <Item key="imagePreview" className={styles.imageViewArea}>
          {this.renderCurrentImage()}
        </Item>
        <Item key="imageActions" className={styles.imageActions}>
          {this.renderImageActions()}
        </Item>
      </Box>
    );
  }

  render() {
    return (
      <div className={styles.console}>
        <Header></Header>
        {(this.props.initializing) ? this.renderPleaseStandBy() : this.renderConsole()}
        <Footer logout={this.handleLogout} login={this.handleLogin}></Footer>
        <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
          <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

LiveViewConsole.propTypes = LiveViewConsoleProps.propTypes;
LiveViewConsole.defaultProps = LiveViewConsoleProps.defaultProps;

LiveViewConsole.connector = (state) => {
  const initializing = !state.appState.ready;

  const proxy = state.appState.config.proxy;
  const campaign = state.appState.config.campaigns[state.liveState.campaign];

  const title = campaign.name;
  const liveEvent = campaign.liveEvent;
  const categories = campaign.categories;
  const categoryDisplayNames = campaign.categoryDisplayNames;
  const categoryArchetype = campaign.categoryArchetype;
  
  const eventType = liveEvent["event-type"];
  const eventFeatures = liveEvent["event-features"] || [];
  
  const placesNames = liveEvent.placesLongnames;
  const shortPlacesNames = liveEvent.placesShortnames;
  const totalHonors = liveEvent.totalHonors;
  const awardMap = liveEvent.awardMap || {};
  const specialAwardTitle = campaign.specialAwardTitle;
  const specialAwardCategory = campaign.specialAwardCategory;

  const eventDate = state.liveState.eventDate;

  const eventState = state.liveState.gamePlay.state;
  const showState = state.liveState.gamePlay.showState;
  const scores = state.liveState.gamePlay.scores;
  const awards = state.liveState.gamePlay.awards;
  const scoringState = state.liveState.gamePlay.scoringState;
  const currentCategory = state.liveState.gamePlay.currentCategory;
  const currentSpotlight = state.liveState.gamePlay.spotlightImage;
  const modality = state.liveState.scoringSystem.modality;
  const scoringType = state.liveState.scoringSystem.type;
  
  const minScore = state.liveState.minScore;
  const maxScore = state.liveState.maxScore;
  const totalJudges = state.liveState.totalJudges;
  
  const scoringRound = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.type", "");
  const scoringRoundId = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.id", "");
  const totalImagesInCategory = currentCategory ? Object.values(state.images).filter(image => image.category === currentCategory).length : 0;
  const totalDqInCategory = currentCategory ? state.liveState.gamePlay.dq.map(id => state.images[id] || {}).filter(image => image.category === currentCategory).length : 0;
  const totalImagesThisRound = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.imageCount", 0);
  const toGo = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.remaining", 0);
  const imagesToScore = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.imagesToScore", []);
  const imagesInRound = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.images", []);
  const labelInfo = state.liveState.gamePlay.labelInfo;
  const hasEntitlements = Boolean(state.appState.config.campaigns[state.liveState.campaign].liveEvent.entitlements);
  const canPrep = !labelInfo && (modality === 'printed-image' || hasEntitlements);
  const isOperator = hasScope(state, 'cyclone.live.operator');
  const bestInShow = state.liveState.gamePlay.bestInShow;

  const canPrepMidCycleReport = scoringRound !== 'initial' && 
    eventFeatures.includes('mid-cycle-report');

  const getCurrentMidCycleReportId = () => {
    return `${state.liveState.campaign}-${currentCategory}-${scoringRound}-${scoringRoundId}`;
  };
  const getImageURL = (id) => {
    return state.images[id].url;
  };
  const getImageInfo = (id) => {
    return state.images[id];
  };
  const getScoreById = (id) => {
    return scores[id];
  };
  const getVotesById = (id) => {
    return lodash.get(state, `liveState.gamePlay.votes[${id}]`, 0);
  };
  const getPrintNumberById = (id) => {
    return lodash.get(state, `liveState.gamePlay.labelInfo.ordinalMap[${id}]`, '');
  };
  const getAwardById = (id) => {
    return awards[id];
  };

  return {
    initializing,
    eventState,
    showState,
    scoringState,
    currentSpotlight,
    getImageURL,
    getImageInfo,
    getScoreById,
    getVotesById,
    getAwardById,
    getPrintNumberById,
    eventType,
    eventDate,
    eventFeatures,
    totalHonors,
    awards,
    paused: state.liveState.gamePlay.paused,
    scoringRound,
    currentCategory,
    totalImagesInCategory,
    totalDqInCategory,
    totalImagesThisRound,
    toGo,
    title,
    minScore,
    maxScore,
    totalJudges,
    categories,
    categoryDisplayNames,
    scoringType,
    modality,
    imagesToScore,
    imagesInRound,
    placesNames,
    shortPlacesNames,
    awardMap,
    specialAwardTitle,
    specialAwardCategory,
    scores,
    labelInfo,
    proxy,
    isOperator,
    canPrep,
    bestInShow,
    categoryArchetype,
    getCurrentMidCycleReportId,
    canPrepMidCycleReport,
  };
};

LiveViewConsole.commander = (dispatch) => {
  return {
    startGame: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'start' } }),
    prepGame: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'prep', method: 'POST' } }),
    resumeGame: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'resume' } }),
    revealSecrets: () => dispatch({ type: actions.REVEAL_SECRETS }),
    prepSleeves: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'sleeves', method: 'POST' } }),
    prepReport: (type) => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'report', params: { type }, method: 'POST' } }),
    scoreImage: (image, score) => dispatch({ type: actions.SCORE_IMAGE, payload: { image, score, override: true } }),
    voteForImage: (image, vote) => dispatch({ type: actions.VOTE_IMAGE, payload: { image, vote, override: true } }),
    rescoreImage: (image) => dispatch({ type: actions.PRESENT_IMAGE, payload: { image, rescore: true } }),
    eliminateImage: (image) => dispatch({ type: actions.ELIMINATE_IMAGE, payload: { image } }),
    awardImage: (image) => dispatch({ type: actions.AWARD_IMAGE, payload: { image } }),
    spotlightImage: (image) => dispatch({ type: actions.SPOTLIGHT_IMAGE, payload: { image } }),
    previewImagesInRound: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'preview', method: 'PUT' } }),
    removeSpotlight: () => dispatch({ type: actions.REMOVE_SPOTLIGHT }),
    testScoringDevices: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'test', method: 'POST' } }),
    logout: () => dispatch({ type: actions.LOGOUT }),
    login: () => dispatch({ type: actions.LOGIN }),
    openDirectory: (id) => dispatch({ type: actions.NAVIGATE, payload: { location: `/directory/${id}`, method: 'push' } }),
  };
};


export default connect(
  LiveViewConsole.connector,
  LiveViewConsole.commander
)(LiveViewConsole);
