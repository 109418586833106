import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import lodash from 'lodash';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";

import { GlobalHotKeys } from 'react-hotkeys';

import AppController from '../../Controllers/AppController';

import LowerThirdBanner from '../../Components/LowerThirdBanner';
import Interstitial from '../../Components/Interstitial';
import Logo from '../../Components/Logo';
import Header from '../Header';
import Footer from '../Footer';

import * as actions from '../../model/actions';

import { getTestImage } from "../../TestImages";

import styles from './ScorePad.module.scss';

const parseVote = (vote) => {
  if (typeof (vote) === 'string') {
    return Boolean(/up|in|true|yes|^[1-9]/i.exec(vote.toString().trim()));
  }
  return Boolean(vote);
};

const rehydrateVote = (vote) => {
  switch (vote) {
    case null: return '';
    case undefined: return '';
    case true: return 'in';
    case false: return 'out';
    default: {
      // not sure what this is, convert to boolean...
      return rehydrateVote(parseVote(vote));
    }
  }
};

class ScorePad extends Component {

  constructor(props) {
    super(props);

    this.focusableElement = React.createRef();
    this.handleLogout = this.handleLogout.bind(this);
    this.swiperRef = React.createRef();

    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);

    this.swiperKeyMap = {
      Next: 'ArrowRight',
      Prev: 'ArrowLeft'
    };

    this.swiperHandlers = {
      Next: this.handleNextSlide,
      Prev: this.handlePrevSlide
    };

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info',
      score: props.score,
      vote: props.vote,
      mode: 'idle',
      showState: [],
      bannerId: undefined,
    };

    AppController.on(AppController.errorEvent, (snackbarMessage) => {
      return {
        openSnackbar: true,
        snackbarMessage,
        snackbarSeverity: 'error',
      };
    });
  }


  handleNextSlide() {
    this.swiperRef.current.swiper.autoplay.stop();
    this.swiperRef.current.swiper.slideNext(300);
  }
  handlePrevSlide() {
    this.swiperRef.current.swiper.autoplay.stop();
    this.swiperRef.current.swiper.slidePrev(300);
  }

  renderPleaseStandBy() {
    return (
      <Interstitial />
    );
  }

  renderBallot() {
    const registerVote = () => {
      this.props.voteForImage(this.props.currentImage, this.state.vote);
      this.setState({
        mode: 'registering'
      });
    };

    const handleVoteClick = (vote) => {
      this.setState({
        vote,
        mode: 'voting'
      });
    };

    const handleAbstain = () => {
      this.props.abstainFromVoting(this.props.currentImage);
    };


    const hasVoted = this.props.vote !== undefined;
    const hasVoteChanged = hasVoted && this.props.vote !== parseVote(this.state.vote);

    const voteButtonClasses = clsx(
      styles.actionButton,
      styles.voteButton,
      {
        [styles.voted]: hasVoted && !hasVoteChanged,
      }
    );
    const abstainButtonClasses = clsx(
      styles.actionButton,
      styles.abstainButton,
      {
        [styles.abstained]: this.props.abstained
      }
    );

    const voteButton = (
      <Box>
        <Button
          className={voteButtonClasses}
          disabled={!this.state.vote}
          onClick={registerVote}
          variant="contained"
        >
          <Box className={styles.buttonText}>Cast Ballot</Box>
        </Button>
        {
          this.props.canAbstain && (
            <Button
              className={abstainButtonClasses}
              onClick={handleAbstain}
              variant="contained"
            >
              <Box className={styles.buttonText}>Abstain</Box>
            </Button>
          )
        }
      </Box>
    );
   
    const items = ['in', 'out'].map(text => {
      const classes = [styles.touchTone];
      if (text === this.state.vote) {
        classes.push(styles.selected);
      }
      return (
          <Grid key={`vote-${text}`} item className={clsx(classes)} onClick={() => handleVoteClick(text)}>
            <Button><Typography variant="h3">{text}</Typography></Button>
          </Grid>
      );
    });

    const keyMap = {
      in: [`Digit1`, `Numpad1`, `1`],
      out: [`Digit0`, `Numpad0`, `0`],
      submit: ['Enter', 'NumpadEnter']
    };
  
    const handlers = {
      in: () => handleVoteClick('in'),
      out: () => handleVoteClick('out'), 
      submit: registerVote
    };

    return (
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
        <Box key="ballotBox" className={styles.scoringBox}>
          <Box key="upperThird" sx={{ padding: '30px', color: 'white' }}>
            {voteButton}
          </Box>
          <Grid key="scorePad" container className={styles.ballot}>
            {items}
          </Grid>
        </Box>
      </GlobalHotKeys>
    );
  }

  renderScorePad() {
    const doScore = (score) => {
      if (lodash.clamp(score, this.props.minScore, this.props.maxScore) !== score) {
        this.setState(() => {
          return {
            openSnackbar: true,
            snackbarMessage: `Score must be between ${this.props.minScore} and ${this.props.maxScore}`,
            score: this.props.score,
            mode: 'idle'
          };
        });
      } else {
        this.props.scoreImage(this.props.currentImage, score);
        this.setState({
          score,
          mode: 'registering'
        });
      }
    };

    const handleScoreImage = () => {
      doScore(this.state.score);
    };

    const handleAbstain = () => {
      this.props.abstainFromScoring(this.props.currentImage);
    };

    const handleNumberClick = (event) => {
      const score = parseInt(event.currentTarget.textContent.trim());
      this.setState({
        score,
        mode: 'scoring'
      });
    };

    const handleNumberKeyPress = (event) => {
      const score = parseInt(event.key);
      if (score >= this.props.minScore && score <= this.props.maxScore) {
        this.setState({
          score,
          mode: 'scoring'
        });
      }
    };

    const handleSlide = (slider, value) => {
      const score = value;
      this.setState({
        score,
        mode: 'scoring'
      });
    };

    const scoreButtonClasses = clsx(
      styles.actionButton,
      styles.scoreButton,
      {
        [styles.scored]: this.props.score === this.state.score
      }
    );
    const abstainButtonClasses = clsx(
      styles.actionButton,
      styles.abstainButton,
      {
        [styles.abstained]: this.props.abstained
      }
    );

    const scoreButton = (
      <Box>
        <Button
          className={scoreButtonClasses}
          onClick={handleScoreImage}
          variant="contained"
          disabled={this.state.score === 0}
        >
          <Box className={styles.buttonText}>Score Image</Box>
        </Button>
        {this.props.canAbstain && (
          <Button
            className={abstainButtonClasses}
            onClick={handleAbstain}
            variant="contained"
            disabled={this.props.abstained}
          >

            <Box className={styles.buttonText}>Abstain</Box>
          </Button>
        )}
      </Box>
    );

    const items = new Array(this.props.maxScore - this.props.minScore + 1).fill(null).map((v, index) => {
      const classes = [styles.touchTone];
      if (this.props.minScore + index === this.state.score) {
        classes.push(styles.selected);
      }
      return (
          <Grid key={`touchTone-${index + this.props.minScore}`} item className={clsx(classes)} onClick={handleNumberClick}>
            <Typography variant="h3">{index + this.props.minScore}</Typography>
          </Grid>
      );
    });


    const keyMap = {
      submit: ['Enter', 'NumpadEnter'],
    };
    const handlers = {
      submit: handleScoreImage,
    };

    for (let i = this.props.minScore; i <= this.props.maxScore; i++) {
      keyMap[`score${i}`] = [`Digit${i}`, `Numpad${i}`, `${i}`];
      handlers[`score${i}`] = handleNumberKeyPress;
    }
  
    return (
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
        <Box key="scoringBox" className={styles.scoringBox}>
          <Box key="upperThird" className={styles.score}>
            <Typography variant="h1">{this.state.score}</Typography>
            {scoreButton}
          </Box>
          <Slider
            key="scoringSlider"
            aria-label="Score"
            className={styles.slider}
            value={this.state.score}
            step={1}
            marks
            min={this.props.minScore}
            max={this.props.maxScore}
            onChange={handleSlide}
          />
          <Grid key="scorePad" container className={styles.numberPad}>
            {items}
          </Grid>
        </Box>
      </GlobalHotKeys>
    );
  }

  handleFocus() {
    if (this.focusableElement.current) {
      const input = this.focusableElement.current.querySelector('input');
      if (document.activeElement !== input) {
        input.focus();
      }
    }
  }

  componentDidMount() {
    this.handleFocus();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.showState) !== JSON.stringify(this.props.showState)) {
      this.setState(() => {
        return {
          score: this.props.score || 0,
          vote: rehydrateVote(this.props.vote),
          mode: 'idle'
        };
      });
      this.handleFocus();
    } else if (this.props.score !== this.state.score && this.state.mode === 'idle') {
      this.setState(() => {
        return {
          score: this.props.score || 0,
        };
      });
    } else if (rehydrateVote(this.props.vote) !== this.state.vote && this.state.mode === 'idle') {
      this.setState(() => {
        return {
          vote: rehydrateVote(this.props.vote),
        };
      });
    } else if (this.props.score === this.state.score && this.state.mode === 'registering') {
      this.setState(() => {
        return {
          mode: 'idle'
        };
      });
    }
  }

  handleLogout() {
    this.props.logout();
  }

  renderSpotlight() {
    const url = this.props.getImageURL(this.props.spotlightImage);
    return (
      <div className={styles.soloImage}>
        <div key={this.props.spotlightImage.id} className={styles.image} style={{ backgroundImage: `url(${url})` }} />
      </div>
    );
  }

  renderImageIdentification(id, index) {
    const imageNumber = this.props.getImageOrdinalById(id) || index;

    return (
      <div className={styles.identificationBug}>
        <div className={styles.pretext}>
          <Logo variant="chip" className={styles.logo} />
          <Typography variant="h6" component="span" sx={{ textAlign: "left" }}>
            image
          </Typography>
        </div>
        <div className={styles.indexNumber}>{imageNumber}</div>
      </div>
    );
  }

  renderImageSwiper() {
    let imageUrls = this.props.showState;
    imageUrls = Array.isArray(imageUrls) ? imageUrls : [imageUrls];

    return (
      <>
        <GlobalHotKeys keyMap={this.swiperKeyMap} handlers={this.swiperHandlers}>
          <div className={styles.Swiper} >
            <Swiper
              className={styles.Show}
              ref={this.swiperRef}
              pagination={false}
              navigation={true}
              keyboard={true}
              autoplay={{ delay: 3000 }}
              modules={[Autoplay, Pagination, Navigation]}

            >
              {
                imageUrls.map((id, index) => {
                  const url = this.props.getImageURL(id);
                  const styleObj = {
                    backgroundImage: `url(${url})`
                  };
                  return (
                    <SwiperSlide key={id}>
                      <div className={styles.reviewSlide} style={styleObj} data-index={index + 1}>
                        {this.renderImageIdentification(id, index + 1)}
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </GlobalHotKeys>
      </>
    );
  }

  renderScoringGrid() {
    let content;

    if (this.props.scoringState === 'voting') {
      content = this.renderBallot();
    } else {
      content = this.renderScorePad();
    }

    const currentImageURL = this.props.getImageURL(this.props.currentImage);
    const backgroundImage = `url(${currentImageURL})`;

    return (
      <Grid container className={styles.scoringGrid}>
        <Grid item key="imagePreview" className={styles.imagePreview}>
          <div key="image" className={styles.image} style={{ backgroundImage }} />
        </Grid>
        <Grid item key="imageActions" className={styles.imageActions}>
          <div key="content" className={styles.content}>
            {content}
          </div>
        </Grid>
      </Grid>
    );
  }

  renderFinalScoreBumper(title, maker, clubName, pretext, score) {
    return (
      <div className={styles.bumper}>
        <div className={styles.info}>
          <Typography variant="h3">{`${title} by ${maker}`}</Typography>
          <Typography variant="h4" className={styles.clubName}>{`${clubName}`}</Typography>
        </div>
        <div className={styles.scoreBox}>
          {pretext && <div className={styles.pretext}>
            <Typography variant="h5">
              {pretext}
            </Typography>
          </div>}
          <div className={styles.scoreNumber}>{Math.ceil(score)}</div>
          <div className={styles.scoreText}>Points</div>
        </div>
      </div>
    );
  }

  renderWinnersSwiper() {
    const data = this.props.imageUrls.map(({ id, url }) => {
        const info = this.props.getImageInfo(id);
        const score = this.props.getScoreById(id);
        const clubName = this.props.getClubNameById(info.userId);
        return {
          id,
          info,
          score,
          url, 
          clubName,
        };
      })
      .sort((a, b) => {
        if (this.props.eventType === 'placed-competition-with-mentions' && this.props.eventState === 'report-winners') {
          const awardA = this.props.getAwardById(a.id);
          const awardB = this.props.getAwardById(b.id);
          return awardA - awardB;
        } else {
          return a.score - b.score;
        }
      })
      .map(({ id, info, score, clubName, url }) => {
        let banner;
        if (this.props.eventType === 'placed-competition-with-mentions') {
          const award = this.props.getAwardById(id);
          const place = this.props.awardMap[award] || award;
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, place, score);
        } else if (this.props.eventType === 'placed-competition') {
          const placeIndex = this.props.imagesInRound.indexOf(id);
          const place = `${this.props.placesNames[placeIndex]}`;
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, place, score);
        } else if (this.props.eventType === 'top-honors') {
          banner = this.renderFinalScoreBumper(info.title, info.maker, undefined, undefined, score);
        } else if (this.props.eventType === 'honors-competition') {
          const award = this.props.getAwardById(id);
          const honor = this.props.awardMap[award] || award;
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, honor, score);
        }

        return {
          id,
          info,
          score,
          url,
          banner
        };
      });

    const delay = 10000;

    return (
      <>
        <GlobalHotKeys keyMap={this.swiperKeyMap} handlers={this.swiperHandlers}>
          <div className={styles.Swiper} >
            <Swiper
              className={styles.Show}
              ref={this.swiperRef}
              pagination={false}
              navigation={false}
              autoplay={{ delay }}
              modules={[Autoplay]}
              onAfterInit={(swiper) => {
                this.setState(() => {
                  return {
                    bannerId: lodash.get(data, `[${swiper.activeIndex}].id`),
                  };
                });
              }}
              onSlideChange={(swiper) => {
                this.setState(() => {
                  return {
                    bannerId: lodash.get(data, `[${swiper.activeIndex}].id`),
                  };
                });
              }}
            >
              {
                data.map(({
                  id,
                  info,
                  score,
                  url,
                  banner
                }) => {
                  return (
                    <SwiperSlide key={id} className={styles.slide}>
                      <div className={styles.winnerImage}>
                        <img src={url} alt={id} className={styles.image} />
                      </div>
                      {banner && this.state.bannerId === id &&
                        <LowerThirdBanner delay='900ms' duration={6000} key={`${this.state.bannerId}-bumper-results-final`}>
                          {banner}
                        </LowerThirdBanner>
                      }
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </GlobalHotKeys>
      </>
    );
  }


  renderImagePreview() {
    if (this.props.spotlightImage) {
      return this.renderSpotlight();
    }
    return this.renderImageSwiper();
  }

  render() {

    let content;

    const previewStates = [
      'preview-images',
      'elimination',
      'pick-winners',
      'choosing-best'
    ];

    const renderPreview = previewStates.includes(this.props.eventState)
                            && this.props.isWideScreen;

    const renderWinnersSwiper = this.props.isWideScreen &&
                                this.props.eventState === 'report-winners';

    switch (this.props.scoringState) {
      case 'scoring':
      case 'testing':
      case 'voting':
        content = this.renderScoringGrid();
        break;
      default:
        if (renderPreview) {
          content = this.renderImagePreview();
        } else if (renderWinnersSwiper) {
          content = this.renderWinnersSwiper();
        } else {
          content = this.renderPleaseStandBy();
        }
    }

    return (
      <div className={styles.scorePad}>
        <Header></Header>
        {content}
        { !renderWinnersSwiper && (<Footer logout={this.handleLogout}></Footer>) }
        <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
          <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}


ScorePad.propTypes = {
  eventType: PropTypes.string,
  logout: PropTypes.func,
  eventState: PropTypes.oneOf(['upcoming', 'scoring-test', 'preview-images', 'running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners', 'intermission', 'report-winners', 'choosing-best', 'ended']),
  showState: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  scoringState: PropTypes.oneOf(['disabled', 'picking', 'scoring', 'voting', 'testing']),
  getImageURL: PropTypes.func,
  scoreImage: PropTypes.func,
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  score: PropTypes.number,
  vote: PropTypes.bool,
  spotlightImage: PropTypes.string,
  canAbstain: PropTypes.bool,
  isWideScreen: PropTypes.bool,
  getImageOrdinalById: PropTypes.func,
  getAwardById: PropTypes.func,
  awardMap: PropTypes.objectOf(PropTypes.string),
  imagesInRound: PropTypes.arrayOf(PropTypes.string),
  placesNames: PropTypes.arrayOf(PropTypes.string),
  getImageInfo: PropTypes.func,
  getScoreById: PropTypes.func,
  getClubNameById: PropTypes.func,
  imageUrls: PropTypes.arrayOf(PropTypes.object),
};

ScorePad.defaultProps = {
  eventType: 'placed-competition',
  eventState: 'upcoming',
  showState: 'interstitial',
  scoringState: 'disabled',
  getImageURL: () => { },
  getImageInfo: () => { },
  scoreImage: () => { },
  logout: () => { },
  getImageOrdinalById: () => { },
  score: 0,
  vote: undefined,
  spotlightImage: null,
  canAbstain: false,
  isWideScreen: false,
  getAwardById: () => { },
  getScoreById: () => { },
  getClubNameById: () => { },
  awardMap: {},
  imagesInRound: [],
  placesNames: [],
  imageUrls: [],
};

ScorePad.connector = (state) => {
  const canAbstain = state.appState.config.campaigns[state.liveState.campaign].liveEvent.canAbstain;
  const eventState = state.liveState.gamePlay.state;
  const showState = state.liveState.gamePlay.showState;
  const scoringState = state.liveState.gamePlay.scoringState;
  const minScore = state.liveState.minScore;
  const maxScore = state.liveState.maxScore;
  const currentImage = state.liveState.gamePlay.currentImage;
  const spotlightImage = state.liveState.gamePlay.spotlightImage;
  const ordinalMap = state.liveState.gamePlay.labelInfo && state.liveState.gamePlay.labelInfo.ordinalMap;
  const imagesInRound = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.images", []);
  const eventType = state.appState.config.campaigns[state.liveState.campaign].liveEvent["event-type"];
  
  let imageUrls = state.liveState.gamePlay.showState;
  imageUrls = imageUrls && (Array.isArray(imageUrls) ? imageUrls : [imageUrls]);
  imageUrls = imageUrls.filter(id => !!state.images[id]).map(id => {
    return { id, url: state.images[id].url };
  });


  const getValueFromArray = (property, valueName, defaultValue) => {
    const values = lodash.get(state.liveState.gamePlay, property, []);
    if (!Array.isArray(values)) {
      return defaultValue;
    }

    const value = values
      .filter(value => value.userId === state.appState.user.id)
      .map(value => value[valueName])
      .pop();

    if (value === undefined) {
      return defaultValue;
    }

    return value;
  };

  const computeMyScore = () => getValueFromArray('currentImageScores', 'score', 0);
  const computeMyVote = () => getValueFromArray('currentImageVotes', 'vote', undefined);

  const computeMyAbstention = () => {
    const property = (scoringState === 'voting') ? 'currentImageVotes' : 'currentImageScores';
    return getValueFromArray(property, 'abstain', false);
  };

  const getImageOrdinalById = (id) => {
    return ordinalMap && ordinalMap[id];
  };

  const getImageInfo = (id) => {
    return state.images[id];
  };

  const score = computeMyScore();
  const vote = computeMyVote();
  const abstained = computeMyAbstention();

  const getImageURL = (id) => {
    if (id === 'test') {
      return getTestImage();
    }
    const image = state.images && state.images[id];
    return image && image.url;
  };

  const getAwardById = (id) => {
    return state.liveState.gamePlay.awards[id];
  };

  const getScoreById = (id) => {
    return state.liveState.gamePlay.scores[id];
  };

  const getClubNameById = (id)  => {
    const club = state.appState.config.clubs.find(club => club.userId === id); 
    return club ? club.name : 'Darkroomers';
  };

  const awardMap = state.appState.config.campaigns[state.liveState.campaign].liveEvent.awardMap || {};
  const placesNames = state.appState.config.campaigns[state.liveState.campaign].liveEvent.placesLongnames;


  return {
    eventType,
    eventState,
    showState,
    scoringState,
    currentImage,
    minScore,
    maxScore,
    score,
    vote,
    abstained,
    getImageURL,
    getImageInfo,
    getImageOrdinalById,
    spotlightImage,
    canAbstain,
    isWideScreen: matchMedia('(min-width: 600px)').matches,
    getAwardById,
    getScoreById,
    getClubNameById,
    awardMap,
    imagesInRound,
    placesNames,
    imageUrls,
  };
};

ScorePad.commander = (dispatch) => {
  return {
    scoreImage: (image, score) => dispatch({ type: actions.SCORE_IMAGE, payload: { image, score } }),
    abstainFromScoring: (image) => dispatch({ type: actions.SCORE_IMAGE, payload: { image, abstain: true } }),
    voteForImage: (image, vote) => dispatch({ type: actions.VOTE_IMAGE, payload: { image, vote } }),
    abstainFromVoting: (image) => dispatch({ type: actions.VOTE_IMAGE, payload: { image, abstain: true } }),
    logout: () => dispatch({ type: actions.LOGOUT }),
  };
};


export default connect(
  ScorePad.connector,
  ScorePad.commander
)(ScorePad);
