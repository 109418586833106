export default class HttpError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = 'HTTPError';
    this.statusCode = statusCode;
  }
}

export class AxiosError extends Error {
  constructor(response, body) {
    super(body.message);
    this.name = response.statusText;
    this.statusCode = response.status;
  }
}
