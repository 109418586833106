import testImage from './images/platform93.jpg';

// FIXME: we should be able to rotate through images but
//  asynchronous loading prevents everyone from looking
//  at the same image + reactivity causes a different
//  image to be loaded for each reactive update.

export function getTestImage() {
    return testImage;
}
