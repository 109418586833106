import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Logo.module.scss';

export default function Logo(props) {
  const className = styles[props.variant];
  return (
    <div className={clsx([props.className, className])} />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['full', 'chip', 'dark'])
};

Logo.defaultProps = {
  className: null,
  variant: 'full',
};
