import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { GlobalHotKeys } from 'react-hotkeys';

import lodash from 'lodash';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import Interstitial from '../../Components/Interstitial';

import Header from '../Header';
import Footer from '../Footer';

import * as actions from '../../model/actions';
import {
  hasScope,
  computeCurrentImageScoringData,
} from '../../model/selectors';

import styles from './ScoreBox.module.scss';

class ScoreBox extends Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    this.props.logout();
  }

  renderPleaseStandBy() {
    return (
      <Interstitial />
    );
  }

  computeCellContent(score) {
    const isVoting = this.props.eventState === 'image-voting';

    const votesIcon = [
      <ThumbDownIcon className={styles.voteIcon} />,
      <ThumbUpIcon className={styles.voteIcon} />,
    ];
    const renderScore = () => {
      if (isNaN(score) && score !== undefined) {
        return (
          <Box className={styles.voteIconContainer}>
            <CloseIcon className={styles.voteIcon} />
          </Box>
        );
      }
      return (
        <Typography variant="h2">{score}</Typography>
      );
    };

    const voteIcon = votesIcon[score ? 1 : 0];

    if (!isVoting || score === undefined || isNaN(score)) {
      // renders an empty box if `undefined`...
      return renderScore();
    } else {
      return (
        <Box className={styles.voteIconContainer}>
          {voteIcon}
        </Box>
      );
    }
  }

  renderScoreBox() {
    const isVoting = this.props.eventState === 'image-voting';
    const isTesting = this.props.eventState === 'scoring-test';
    const scoringProp = isVoting ? this.props.votes : this.props.scores;
    const totalProp = isVoting ? this.props.voteTally : this.props.compositeScore;
    const items = new Array(this.props.totalJudges).fill(null).map((v, index) => {
      const score = lodash.get(scoringProp, index);
      const content = this.computeCellContent(score);

      const abstain = score !== undefined && isNaN(score);
      const noScore = score === undefined;

      const classNames = clsx(
        styles.score,
        {
          [styles.abstain]: abstain,
          [styles.noScore]: noScore,
        }
      );
      return (
        <Grid key={`judge-score-${index}`} item className={classNames}>
          {content}
        </Grid>
      );
    });

    const classes = [
      styles.score,
      styles.combined
    ];

    const final = {
      [styles.final]: !isTesting && this.props.isFinal,
    };

    const total = (
      <Grid key={'combined-score'} item className={clsx(classes, final)}>
        <Typography variant="h2">{totalProp}</Typography>
      </Grid>
    );

    const actions = [
    ];

    const keyMap = {
    };

    const handlers = {
      continue: () => this.props.resumeGame(),
      rescore: () => this.props.rescoreImage(this.props.currentImage),
    };
    
    if (this.props.allowRescore) {
      keyMap.rescore = 'Escape';
      const text = isVoting ? 'Revote' : 'Rescore';
      actions.push(
        <Button key="rescore-button" onClick={() => this.props.rescoreImage(this.props.currentImage)}>{text}</Button>
      );
    }

    if (this.props.allowResume) {
      keyMap.continue = ['Enter', 'NumpadEnter'];

      actions.push(
        <Button key="resume-button" onClick={() => this.props.resumeGame()}>Continue</Button>
      );
    }

    const gameActions = [
    ];

    if (actions.length > 0) {
      gameActions.push(
        <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
          <Box key="game-actions" className={clsx(styles.actions, final)}>
            {actions}
          </Box>
        </GlobalHotKeys>
      );
    }

    return (
      <Box>
        <Grid key="scores" container className={styles.scoringBox}>
          {items}
          {total}
        </Grid>
        {gameActions}
      </Box>
    );
  }

  render() {
    const intermissionStates = [
      'upcoming',
      'report-winners',
      'ended',
      'intermission',
      'running'
    ];

    const scoringStates = [
      'scoring',
      'voting',
      'testing',
      'disabled' // we don't hide the score box unless we are picking...
    ];

    const showIntermission = intermissionStates.includes(this.props.eventState)
      || !scoringStates.includes(this.props.scoringState);

    const content = showIntermission ? this.renderPleaseStandBy() : this.renderScoreBox();

    return (
      <div className={styles.scoreBox}>
        <Header></Header>
        {content}
        <Footer logout={this.handleLogout}></Footer>
      </div>
    );
  }
}


ScoreBox.propTypes = {
  logout: PropTypes.func,
  rescoreImage: PropTypes.func,
  resumeGame: PropTypes.func,
  scoringState: PropTypes.oneOf(['disabled', 'picking', 'scoring', 'voting', 'testing']),
  eventState: PropTypes.oneOf(['upcoming', 'scoring-test', 'preview-images', 'running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners', 'intermission', 'report-winners', 'choosing-best', 'ended']),
  minScore: PropTypes.number,
  maxScore: PropTypes.number,
  scores: PropTypes.arrayOf(PropTypes.number),
  votes: PropTypes.arrayOf(PropTypes.number),
  totalScore: PropTypes.number,
  totalJudges: PropTypes.number,
  compositeScore: PropTypes.number,
  voteTally: PropTypes.number,
  currentImage: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  allowResume: PropTypes.bool,
  allowRescore: PropTypes.bool,
  isFinal: PropTypes.bool,
};

ScoreBox.defaultProps = {
  scoringState: 'disabled',
  eventState: 'upcoming',
  logout: () => { },
  resumeGame: () => { },
  rescoreImage: () => { },
  minScore: 6,
  maxScore: 9,
  totalJudges: 3,
  scores: [],
  votes: [],
  voteTally: 0,
  compositeScore: 0,
  currentImage: null,
  allowResume: false,
  allowRescore: false,
  isFinal: false,
};

ScoreBox.connector = (state) => {
  const scoringState = state.liveState.gamePlay.scoringState;
  const eventState = state.liveState.gamePlay.state;
  const minScore = state.liveState.minScore;
  const maxScore = state.liveState.maxScore;
  const totalJudges = state.liveState.totalJudges || 1;

  if (eventState === 'upcoming') {
    return {
      scoringState,
      eventState,
    };
  }

  const {
    currentImage,   // image being scored currently
    scores,         // individual scores (empty array if overridden)
    votes,          // individual votes (empty array if vetoed)
    isFinal,        // true if the scoring has completed
    voteTally,      // final vote count
    compositeScore,  // final score
  } = computeCurrentImageScoringData(state);

  const allowResume = hasScope(state, 'cyclone.live.resume');
  const allowRescore = hasScope(state, 'cyclone.live.rescore-image');

  return {
    scoringState,
    eventState,
    minScore,
    maxScore,
    scores,
    totalJudges,
    compositeScore,
    votes,
    voteTally,
    currentImage,
    allowResume,
    allowRescore,
    isFinal
  };
};

ScoreBox.commander = (dispatch) => {
  return {
    logout: () => dispatch({ type: actions.LOGOUT }),
    resumeGame: () => dispatch({ type: actions.LIVE_COMMAND, payload: { command: 'resume' } }),
    rescoreImage: (image) => dispatch({ type: actions.PRESENT_IMAGE, payload: { image, rescore: true } }),
  };
};


export default connect(
  ScoreBox.connector,
  ScoreBox.commander
)(ScoreBox);
