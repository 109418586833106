import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';

import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';

import Header from '../Header';
import Footer from '../Footer';

import styles from './ImageReviewView.module.scss';
import * as actions from '../../model/actions';

class ImageReviewView extends Component {
    constructor(props) {
        super(props);

        this.renderImageThumbnail = this.renderImageThumbnail.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.applyFilter = this.applyFilter.bind(this);

        this.state = {
            openSnackbar: false,
            snackbarMessage: null,
            snackbarSeverity: 'info',
            filter: '',
        };
    }

    handleCloseSnackbar() {
        this.setState({ openSnackbar: false });
    }

    handleDisqualifyImage(image) {
        this.props.disqualifyImage(image.id);
    }

    applyFilter(filter) {
        this.setState({ filter });
    }

    renderImageThumbnail(image, index) {
        return (
            <Grid sx={{
                maxWidth: '345px',
            }}
                item
                key={`image-${index}`}
                xs={6}
            >
                <Card sx={{
                    maxWidth: 345,
                    minWidth: 345,
                    minHeight: 400,
                    cursor: 'pointer',
                    position: 'relative',
                }}>
                    <CardMedia
                        component="img"
                        alt={image.title}
                        height="200"
                        image={image.url}
                        className={styles.cardMedia}
                    />
                    <div className={styles.cardContent}>
                        <Typography variant="h6">
                            {image.title} by {image.maker}
                        </Typography>
                        <Typography variant="body2" component="span">
                            {image.id}
                        </Typography>
                    </div>
                    <hr />
                    <CardActions className={styles.cardActions}>
                        <Checkbox className={styles.Checkbox} checked={this.props.isImageDisqualified(image)} onChange={() => { this.handleDisqualifyImage(image); }} />
                        <Typography variant="body2" component="span">
                            Disqualify this image
                        </Typography>
                    </CardActions>
                </Card>
            </Grid>
        );
    }

    renderImageGrid() {
        const filter = this.state.filter.toLowerCase();
        const images = Object.values(this.props.images)
            .sort((a, b) => {
                const aFullName = `${a.maker}-${a.title}`.toLowerCase();
                const bFullName = `${b.maker}-${b.title}`.toLowerCase();
                if (aFullName < bFullName) {
                    return -1;
                } else if (aFullName > bFullName) {
                    return 1;
                }
                return 0;
            });
        const cards = images.filter(item => {
            return filter.length === 0 || 
                Object.values(item)
                    .filter(text => text)
                    .find(text => text.toLowerCase().includes(filter));
        }).map(this.renderImageThumbnail);

        return (
            <Box sx={{
                position: 'relative',
                width: '100%',
                overflow: 'auto',
                height: `calc(var(--vph, 100vh) - 63px)`,
            }}>
                <Grid sx={{
                    marginLeft: '10px',
                    marginTop: 0,
                    width: 'calc(100% - 16px)',
                    paddingBottom: '100px',
                    '@media(max-width: 786px)': {
                        flexDirection: 'column',
                    },
                    '& .MuiGrid-item': {
                        maxWidth: '375px',
                        '@media(max-width: 786px)': {
                            paddingLeft: 0,
                            paddingRight: 0,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }
                    }
                }}
                    container
                    rowSpacing={4}
                    columnSpacing={8}
                >
                    {cards}
                </Grid>
            </Box>
        );
    }

    renderNoResultsContent() {
        return (
            <div className={clsx(styles.helpText, styles.lonely)}>
                <SearchIcon fontSize="large" />
                <Typography variant="h6" color="inherit" component="div">
                    You have reached the end of the internet...
                </Typography>
            </div>
        );
    }

    render() {
        let content;
        if (this.props.initializing) {
            content = (
                <div className={styles.loader}>
                    <RingLoader className={styles.loader} color="white" />
                </div>
            );
        } else if (!this.props.ready || this.props.images.length === 0) {
            content = this.renderNoResultsContent();
        } else {
            content = this.renderImageGrid();
        }

        return (
            <div>
                <div className={styles.imageCatalogView}>
                    <Header></Header>
                    {content}
                    <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
                        <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
                            {this.state.snackbarMessage}
                        </Alert>
                    </Snackbar>
                    <Footer filter={this.state.filter} applyFilter={this.applyFilter}></Footer>
                </div>
            </div>
        );
    }
}

ImageReviewView.propTypes = {
    initializing: PropTypes.bool,
    ready: PropTypes.bool,
    disqualifyImage: PropTypes.func,
    isImageDisqualified: PropTypes.func,
};

ImageReviewView.defaultProps = {
    initializing: true,
    ready: false,
    disqualifyImage: () => { },
    isImageDisqualified: () => { return false; },
};

ImageReviewView.connector = (state) => {
    const ready = state.liveState.gamePlay.state === 'upcoming';
    const initializing = !state.appState.ready;
    const images = state.images;

    const isImageDisqualified = (image) => !!(state.liveState.gamePlay.dq && state.liveState.gamePlay.dq.includes(image.id));

    return {
        ready,
        images,
        initializing,
        isImageDisqualified,
    };
};

ImageReviewView.commander = (dispatch) => {
    return {
        disqualifyImage: (id) => dispatch({ type: actions.DISQUALIFY_IMAGE, payload: { image: id } }),
    };
};


export default connect(
    ImageReviewView.connector,
    ImageReviewView.commander
)(ImageReviewView);
