
// import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Logo from '../Logo';

import styles from './LowerThirdBanner.module.scss';

export default class LowerThirdBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animating: styles.in,
        };
        this.animationEnded = this.animationEnded.bind(this);
        this.animationStarted = this.animationStarted.bind(this);
        this.ref = React.createRef();
        this.timer = null;
    }

    animationEnded() {
        if (this.state.animating === styles.in) {
            this.timer = setTimeout(() => {
                this.timer = null;
                this.setState({
                    animating: styles.out
                });
            }, this.props.duration);
        } else if (this.state.animating === styles.out) {
            this.props.onFinished();
        }
    }

    animationStarted() {
        if (this.state.animating === styles.in) {
            this.props.onStarted();
        }
    }

    componentWillUnmount() {
        if (this.ref.current) {
            this.ref.current.removeEventListener("animationend", this.animationEnded);
            this.ref.current.removeEventListener("animationstart", this.animationStarted);
        }
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    render() {
        const { children } = this.props;
        const styleObj = {
            animationDelay: this.props.delay,
        };
        return (
            <Box
                id={this.props.id}
                ref={this.ref}
                className={clsx(styles.lowerThird, styles.slide, this.state.animating)}
                style={styleObj}
                onAnimationEnd={this.animationEnded}
                onAnimationStart={this.animationStarted}
            >
                <Box
                    className={styles.lowerThirdContent}
                >
                    <Logo variant="chip" className={styles.logo} />
                    {children}
                </Box>
            </Box>
        );
    }
}

LowerThirdBanner.propTypes = {
    onStarted: PropTypes.func,
    onFinished: PropTypes.func,
    delay: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
};

LowerThirdBanner.defaultProps = {
    onStarted: () => { },
    onFinished: () => { },
    delay: '2s',
    duration: 900,
};
