import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { GlobalHotKeys } from 'react-hotkeys';

import FullScreen from '../../Components/FullScreen';
import Interstitial from '../../Components/Interstitial';
import LowerThirdBanner from '../../Components/LowerThirdBanner';
import Logo from '../../Components/Logo';
import { getTestImage } from "../../TestImages";

import {
  computeCurrentImageScoringData,
} from '../../model/selectors';

import styles from './LiveView.module.scss';

class LiveView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bannerId: undefined,
      showBanner: false,
    };

    this.swiperRef = React.createRef();
    this.liveViewRef = React.createRef();

    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);
    this.clearBanner = this.clearBanner.bind(this);

    this.swiperKeyMap = {
      Next: 'ArrowRight',
      Prev: 'ArrowLeft'
    };

    this.swiperHandlers = {
      Next: this.handleNextSlide,
      Prev: this.handlePrevSlide
    };
  }

  handleNextSlide() {
    this.swiperRef.current.swiper.autoplay.stop();
    this.swiperRef.current.swiper.slideNext(300);
  }
  handlePrevSlide() {
    this.swiperRef.current.swiper.autoplay.stop();
    this.swiperRef.current.swiper.slidePrev(300);
  }

  renderInterstitial() {
    return (
      <Interstitial message="We'll Be Right Back" />
    );
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.imageUrls) !== JSON.stringify(this.props.imageUrls)
        || prevProps.eventState !== this.props.eventState 
        || prevProps.showInterstitial !== this.props.showInterstitial
        || prevProps.spotlightImage !== this.props.spotlightImage
    ) {

      const showBanner = this.props.imageUrls.length === 1
        && this.props.eventState !== 'scoring-test'
        && this.props.eventState !== 'report-winners'
        && this.props.eventState !== 'preview-images'
        && !this.props.showInterstitial;

      this.setState(() => {
        return {
          showBanner
        };
      });
    }
  }

  renderFinalScoreBumper(title, maker, clubName, pretext, score) {
    return (
      <div className={styles.bumper}>
        <div className={styles.info}>
          <Typography variant="h3">{`${title} by ${maker}`}</Typography>
          <Typography variant="h4" className={styles.clubName}>{`${clubName}`}</Typography>
        </div>
        <div className={styles.scoreBox}>
          {pretext && <div className={styles.pretext}>
            <Typography variant="h5">
              {pretext}
            </Typography>
          </div>}
          <div className={styles.scoreNumber}>{Math.ceil(score)}</div>
          <div className={styles.scoreText}>Points</div>
        </div>
      </div>
    );
  }

  renderInProgressScoreBumper(id, score) {
    const image = this.props.getImageInfo(id);
    const isVoting = this.props.eventState === 'image-voting';
    const label = isVoting ? 'Votes' : 'Points';
    return (
      <div className={styles.bumper}>
        <div className={styles.info}>
          <Typography variant="h3">{image.title}</Typography>
        </div>
        {score !== undefined && (
          <div className={styles.scoreBox}>
            <div className={styles.scoreNumber}>{score}</div>
            <div className={styles.scoreText}>{label}</div>
          </div>
        )}
      </div>
    );
  }

  renderWinnersSwiper() {
    const data = this.props.imageUrls.map(({ id, url }) => {
        const info = this.props.getImageInfo(id);
        const score = this.props.getScoreById(id);
        const clubName = this.props.getClubNameById(info.userId);
        return {
          id,
          info,
          score,
          url, 
          clubName,
        };
      })
      .sort((a, b) => {
        if (this.props.eventType === 'placed-competition-with-mentions' && this.props.eventState === 'report-winners') {
          const awardA = this.props.getAwardById(a.id);
          const awardB = this.props.getAwardById(b.id);
          return awardA - awardB;
        } else {
          return a.score - b.score;
        }
      })
      .map(({ id, info, score, clubName, url }) => {
        let banner;
        if (this.props.eventType === 'placed-competition-with-mentions') {
          const award = this.props.getAwardById(id);
          const place = this.props.awardMap[award] || award;
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, place, score);
        } else if (this.props.eventType === 'placed-competition') {
          const placeIndex = this.props.imagesInRound.indexOf(id);
          const place = `${this.props.placesNames[placeIndex]}`;
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, place, score);
        } else if (this.props.eventType === 'top-honors') {
          banner = this.renderFinalScoreBumper(info.title, info.maker, undefined, undefined, score);
        } else if (this.props.eventType === 'honors-competition') {
          const award = this.props.getAwardById(id);
          const honor = this.props.awardMap[award] || award;
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, honor, score);
        }

        return {
          id,
          info,
          score,
          url,
          banner
        };
      });

    const delay = 10000;

    return (
      <>
        <GlobalHotKeys keyMap={this.swiperKeyMap} handlers={this.swiperHandlers}>
          <div className={styles.Swiper} >
            <Swiper
              className={styles.Show}
              ref={this.swiperRef}
              pagination={false}
              navigation={false}
              autoplay={{ delay }}
              modules={[Autoplay]}
              onAfterInit={(swiper) => {
                this.setState(() => {
                  return {
                    bannerId: lodash.get(data, `[${swiper.activeIndex}].id`),
                  };
                });
              }}
              onSlideChange={(swiper) => {
                this.setState(() => {
                  return {
                    bannerId: lodash.get(data, `[${swiper.activeIndex}].id`),
                  };
                });
              }}
            >
              {
                data.map(({
                  id,
                  info,
                  score,
                  url,
                  banner
                }) => {
                  return (
                    <SwiperSlide key={id} className={styles.slide}>
                      <div className={styles.winnerImage}>
                        <img src={url} alt={id} className={styles.image} />
                      </div>
                      {banner && this.state.bannerId === id &&
                        <LowerThirdBanner delay='900ms' duration={6000} key={`${this.state.bannerId}-bumper-results-final`}>
                          {banner}
                        </LowerThirdBanner>
                      }
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </GlobalHotKeys>
      </>
    );
  }

  renderImageIdentification(id, index) {
    const imageNumber = this.props.getImageOrdinalById(id) || index;

    return (
      <div className={styles.identificationBug}>
        <div className={styles.pretext}>
          <Logo variant="chip" className={styles.logo} />
          <Typography variant="h6" component="span" sx={{ textAlign: "left" }}>
            image
          </Typography>
        </div>
        <div className={styles.indexNumber}>{imageNumber}</div>
      </div>
    );
  }

  renderImageSwiper() {
    
    return (
      <>
        <GlobalHotKeys keyMap={this.swiperKeyMap} handlers={this.swiperHandlers}>
          <div className={styles.Swiper} >
            <Swiper
              className={styles.Show}
              ref={this.swiperRef}
              pagination={false}
              keyboard={true}
              autoplay={{ delay: 3000 }}
              modules={[Autoplay]}

            >
              {
                this.props.imageUrls.map(({ id, url }, index) => {
                  const styleObj = {
                    backgroundImage: `url(${url})`
                  };
                  return (
                    <SwiperSlide key={id}>
                      <div className={styles.reviewSlide} style={styleObj}></div>
                      {this.renderImageIdentification(id, index + 1)}
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </GlobalHotKeys>
      </>
    );
  }

  renderTestImage() {
    return (
      <div className={styles.soloImage}>
        <div key="test-image" className={styles.image} style={{ backgroundImage: `url(${getTestImage()})` }} />
      </div>
    );
  }

  renderSpotlight() {
    return (
      <div className={styles.soloImage}>
        <div key={this.props.spotlightImage.id} className={styles.image} style={{ backgroundImage: `url(${this.props.spotlightImage.url})` }} />
      </div>
    );
  }

  renderSoloImage() {
    const image = this.props.imageUrls[0];

    const isVoting = this.props.eventState === 'image-voting';
    const isEliminating = this.props.eventState === 'elimination';
    const totalProp = isVoting ? this.props.voteTally : this.props.combinedScore;

    let banner;
    if (totalProp !== null) {
      const innards = this.renderInProgressScoreBumper(image.id, totalProp);
      const id = `${image.id}-bumper-final`;
      banner = (
        <LowerThirdBanner id={id} key={id} delay="50ms" duration={2200}>
          {innards}
        </LowerThirdBanner>
      );
    } else if (this.state.showBanner) {
      const innards = this.renderInProgressScoreBumper(image.id);
      const id = `${image.id}-bumper`;
      banner = (
        <LowerThirdBanner id={id} key={id} delay="800ms" duration={1800} onFinished={this.clearBanner}>
          {innards}
        </LowerThirdBanner>
      );
    }

    let imageBug;

    if (isVoting || isEliminating) {
      imageBug = this.renderImageIdentification(image.id, 1);
    }

    return (
      <div className={styles.soloImage} >
        <div key={image.id} className={styles.image} style={{ backgroundImage: `url(${image.url})` }} />
        {banner}
        {imageBug}
      </div >
    );
  }

  clearBanner() {
    this.setState(() => {
      return {
        showBanner: false
      };
    });
  }

  render() {
    let innards;
    if (this.props.eventState === 'scoring-test') {
      innards = this.renderTestImage();
    } else if (this.props.spotlightImage) {
      innards = this.renderSpotlight();
    } else if (this.props.eventState === 'report-winners') {
      innards = this.renderWinnersSwiper();
    } else if (this.props.eventState === 'preview-images') {
      innards = this.renderImageSwiper();
    } else if (this.props.showInterstitial) {
      innards = this.renderInterstitial();
    } else if (this.props.imageUrls.length > 1) {
      innards = this.renderImageSwiper();
    } else if (this.props.imageUrls.length === 0) {
      innards = this.renderInterstitial();
    } else {
      innards = this.renderSoloImage();
    }

    const buttonStyles = {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'auto',
      backgroundColor: 'white',
    };
    return (
      <FullScreen buttonStyles={buttonStyles}>
        <div ref={this.liveViewRef} className={styles.LiveView}>
          {innards}
        </div>
      </FullScreen>
    );
  }
}


LiveView.propTypes = {
  eventType: PropTypes.string,
  eventState: PropTypes.oneOf(['upcoming', 'scoring-test', 'preview-images', 'running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners', 'intermission', 'report-winners', 'choosing-best', 'ended']),
  showInterstitial: PropTypes.bool.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.object),
  placesNames: PropTypes.arrayOf(PropTypes.string),
  imagesInRound: PropTypes.arrayOf(PropTypes.string),
  awardMap: PropTypes.objectOf(PropTypes.string),
  spotlightImage: PropTypes.object,
  getImageInfo: PropTypes.func,
  getScoreById: PropTypes.func,
  getAwardById: PropTypes.func,
  getClubNameById: PropTypes.func,
  getImageOrdinalById: PropTypes.func,
  combinedScore: PropTypes.number,
  voteTally: PropTypes.number,
};

LiveView.defaultProps = {
  eventType: 'placed-competition',
  showInterstitial: true,
  imageUrls: [],
  imagesInRound: [],
  spotlightImage: undefined,
  eventState: 'upcoming',
  placesNames: [],
  awardMap: {},
  getImageInfo: () => { },
  getScoreById: () => { },
  getAwardById: () => { },
  getImageOrdinalById: () => { },
  getClubNameById: () => { },
  voteTally: null,
  combinedScore: null,
};

LiveView.connector = (state) => {
  const initializing = !state.appState.ready;
  let showInterstitial =
    initializing
    || state.liveState.gamePlay.state === 'upcoming'
    || state.liveState.gamePlay.state === 'intermission'
    || state.liveState.gamePlay.state === 'ended'
    || state.liveState.gamePlay.showState.length === 0;

  let imageUrls = [];
  let spotlightImage;

  const awards = state.liveState.gamePlay.awards;
  const scores = state.liveState.gamePlay.scores;
  const placesNames = state.appState.config.campaigns[state.liveState.campaign].liveEvent.placesLongnames;
 
  const imagesInRound = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.images", []);
  const eventType = state.appState.config.campaigns[state.liveState.campaign].liveEvent["event-type"];
  const awardMap = state.appState.config.campaigns[state.liveState.campaign].liveEvent.awardMap || {};
  const eventState = state.liveState.gamePlay.state;
  const ordinalMap = state.liveState.gamePlay.labelInfo && state.liveState.gamePlay.labelInfo.ordinalMap;

  const {
    isFinal,        // true if the scoring has completed
    voteTally,      // final vote count
    combinedScore,  // final score
  } = computeCurrentImageScoringData(state);

  if (!showInterstitial) {
    imageUrls = state.liveState.gamePlay.showState;
    imageUrls = Array.isArray(imageUrls) ? imageUrls : [imageUrls];
    imageUrls = imageUrls.filter(id => !!state.images[id]).map(id => {
      return { id, url: state.images[id].url };
    });

    spotlightImage = state.liveState.gamePlay.spotlightImage ? {
      id: state.liveState.gamePlay.spotlightImage,
      url: state.images[state.liveState.gamePlay.spotlightImage].url
    } : undefined;
  }

  const getImageInfo = (id) => {
    return state.images[id];
  };
  const getScoreById = (id) => {
    return scores[id];
  };

  const getAwardById = (id) => {
    return awards[id];
  };

  const getImageOrdinalById = (id) => {
    return ordinalMap && ordinalMap[id];
  };

  const getClubNameById = (id)  => {
    const club = state.appState.config.clubs.find(club => club.userId === id); 
    return club ? club.name : 'Darkroomers';
  };

  return {
    showInterstitial,
    eventState,
    imageUrls,
    spotlightImage,
    getImageInfo,
    getScoreById,
    getAwardById,
    getClubNameById,
    getImageOrdinalById,
    placesNames,
    imagesInRound,
    eventType,
    awardMap,
    voteTally: isFinal ? voteTally : null,
    combinedScore: isFinal ? combinedScore : null,
  };
};

LiveView.commander = (dispatch) => {
  return {
  };
};


export default connect(
  LiveView.connector,
  LiveView.commander
)(LiveView);
