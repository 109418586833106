import URI from 'urijs';
import EventEmitter from 'eventemitter3';

import { MAKE_API } from "../constants";
import HttpError, { AxiosError } from "../Utils/HttpError";

class Backend extends EventEmitter {
  constructor() {
    super();

    this.imagesEndpoint = MAKE_API('images');
    this.userEndPoint = MAKE_API('user');
    this.liveEndpoint = MAKE_API('live');

    this.healthEndpoint = MAKE_API('health');
    this.configEndpoint = MAKE_API('config');
    //    this.schemasEndpoint = MAKE_API('schemas');

    this.fetchImages = this.fetchImages.bind(this);
    this.fetchImagesForReview = this.fetchImagesForReview.bind(this);
    this.scoreImage = this.scoreImage.bind(this);
    this.voteImage = this.voteImage.bind(this);
    this.eliminateImage = this.eliminateImage.bind(this);
    this.disqualifyImage = this.disqualifyImage.bind(this);
    this.awardImage = this.awardImage.bind(this);

    this.fetchUserInfo = this.fetchUserInfo.bind(this);
    this.fetchState = this.fetchState.bind(this);
    this.fetchHealth = this.fetchHealth.bind(this);
    this.fetchConfiguration = this.fetchConfiguration.bind(this);

    this.removeSpotlight = this.removeSpotlight.bind(this);
    this.spotlightImage = this.spotlightImage.bind(this);
    this.presentImage = this.presentImage.bind(this);
    this.startCategory = this.presentImage.bind(this);
    this.invokeCommand = this.invokeCommand.bind(this);
    this.fetchSecrets = this.fetchSecrets.bind(this);
    this.fetchCeremonyExtras = this.fetchCeremonyExtras.bind(this);

    this.fetchStandings = this.fetchStandings.bind(this);
    //    this.fetchSchemas = this.fetchSchemas.bind(this);

    this.commonHeaders = {
    };
  }

  setAccessToken(token) {
    this.commonHeaders.Authorization = token;
  }

  async fetchUserInfo() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const response = await fetch(this.userEndPoint, options);
    if (!response.ok) {
      await this.throwAxiosError(response);
    }
    const userInfo = await response.json();
    return userInfo;
  }

  async fetchImages(campaignId) {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    try {
      this.emit(this.statusEvent, this.status.fetchingImages);
      const uri = URI.joinPaths(this.imagesEndpoint, campaignId);
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchCeremonyExtras(campaignId) {
    
    const options = { headers: { ...this.commonHeaders } };
    try {
      this.emit(this.statusEvent, this.status.fetchingExtras);
      const uri = URI.joinPaths(this.imagesEndpoint, 'ceremony', 'extras', campaignId);
      const response = await fetch(uri, options);

      if (!response.ok) {
        return [];
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  
  }

  async fetchImagesForReview(campaignId) {
    const options = { headers: { ...this.commonHeaders } };
    try {
      this.emit(this.statusEvent, this.status.fetchingImages);
      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'event', 'review', 'images');
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async voteImage(campaignId, imageId, vote, abstain = false, override = false) {
    const options = {
      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ vote, abstain }),
    };
    try {
      this.emit(this.statusEvent, this.status.votingImage);

      let uri = URI.joinPaths(this.liveEndpoint, campaignId, 'vote', imageId);
      if (override) {
        uri = `${uri}?override=true`;
      }
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async scoreImage(campaignId, imageId, score, abstain = false, override = false) {
    const options = {
      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({ score, abstain }),
    };
    try {
      this.emit(this.statusEvent, this.status.scoringImage);

      let uri = URI.joinPaths(this.liveEndpoint, campaignId, 'score', imageId);
      if (override) {
        uri = `${uri}?override=true`;
      }
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async eliminateImage(campaignId, imageId) {
    const options = {
      headers: {
        ...this.commonHeaders
      },
      method: 'DELETE',
    };
    try {
      this.emit(this.statusEvent, this.status.scoringImage);

      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'score', imageId);
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async disqualifyImage(campaignId, imageId) {
    const options = {
      headers: {
        ...this.commonHeaders
      },
      method: 'DELETE',
    };
    try {
      this.emit(this.statusEvent, this.status.scoringImage);

      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'image', imageId);
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async awardImage(campaignId, imageId) {
    const options = {
      headers: {
        ...this.commonHeaders
      },
      method: 'PUT',
    };
    try {
      this.emit(this.statusEvent, this.status.scoringImage);

      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'award', imageId);
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async spotlightImage(campaignId, imageId) {
    const options = {
      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({}),
    };
    try {
      this.emit(this.statusEvent, this.status.spotlightingImage);

      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'spotlight', imageId);
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async presentImage(campaignId, imageId, rescore = false) {
    const options = {
      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({}),
    };
    try {
      this.emit(this.statusEvent, this.status.presentingImage);

      let uri = URI.joinPaths(this.liveEndpoint, campaignId, 'present', imageId);
      if (rescore) {
        uri = `${uri}?rescore=true`;
      }
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async removeSpotlight(campaignId) {
    const options = {
      headers: {
        ...this.commonHeaders
      },
      method: 'DELETE',
    };
    try {
      this.emit(this.statusEvent, this.status.spotlightingImage);

      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'spotlight');
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async throwAxiosError(response) {
    return new Promise(async (resolve, reject) => {
      if (response.headers
        && response.headers.get('Content-Type')
        && response.headers.get('Content-Type').split(';').shift() === 'application/json') {
        reject(new AxiosError(response, await response.json()));
      } else {
        reject(new HttpError(response.status, response.statusText));
      }
    });
  }

  async fetchHealth() {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const response = await fetch(this.healthEndpoint, options);
    if (!response.ok) {
      await this.throwAxiosError(response);
    }
    const health = await response.json();
    return health;
  }

  async fetchState(campaignId) {
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const uri = URI.joinPaths(this.liveEndpoint, campaignId);
    const response = await fetch(uri, options);
    if (!response.ok) {
      await this.throwAxiosError(response);
    }
    const userInfo = await response.json();
    return userInfo;
  }

  async fetchConfiguration() {
    try {
      this.emit(this.statusEvent, this.status.fetchingConfig);
      const response = await fetch(this.configEndpoint);
      if (!response.ok) {
        await this.throwAxiosError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  async fetchStandings(initiativeId) {
    try {
      this.emit(this.statusEvent, this.status.fetchingStandings);

      const response = await fetch(MAKE_API('standings', initiativeId));

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }


  async fetchSecrets(campaignId) {
    const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'event', 'secrets');
    const options = {
      headers: {
        ...this.commonHeaders,
      },
    };
    const response = await fetch(uri, options);
    const blob = await response.blob();
    return blob;
  }

  async invokeCommand(campaignId, command, params = {}, method = 'PUT') {
    const options = {
      headers: {
        ...this.commonHeaders,
        'Content-Type': 'application/json'
      },
      method,
      body: JSON.stringify(params),
    };
    try {
      const uri = URI.joinPaths(this.liveEndpoint, campaignId, 'event', command);
      const response = await fetch(uri, options);

      if (!response.ok) {
        await this.throwAxiosError(response);
      }

      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }

  // TODO: Move scoring validation to schemas
  /*
  async fetchSchemas() {
    try {
      this.emit(this.statusEvent, this.status.fetchingSchemas);
      const response = await fetch(this.schemasEndpoint);
      if (!response.ok) {
        await this.throwAxiosError(response);
      }
      const body = await response.json();
      return body;
    } finally {
      this.emit(this.statusEvent, this.status.idle);
    }
  }
  */
}

Backend.prototype.statusEvent = 'status';

Backend.prototype.status = {
  idle: 'idle',
  spotlightingImage: 'Spotlight Image',
  presentingImage: 'Present Image',
  scoringImage: 'Scoring Image',
  fetchingImages: 'Fetching Images',
  fetchingCampaigns: 'Fetching Campaigns',
  fetchingConfig: 'Fetching Config',
  fetchingStandings: 'Fetching Standings',
  fetchingExtras: 'Fetching Extras'
};

const backend = new Backend();
export default backend;
