import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RingLoader from 'react-spinners/RingLoader';
import { GlobalHotKeys } from 'react-hotkeys';
import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Grid3x3ViewIcon from '@mui/icons-material/Grid3x3Outlined';
import Grid4x4ViewIcon from '@mui/icons-material/Grid4x4Outlined';

import lodash from 'lodash';

import Header from '../Header';
import Footer from '../Footer';

import { openGridPrintView } from "../../Utils/ReportUtils";

import * as actions from '../../model/actions';
import styles from './LeaderboardView.module.scss';

class LeaderBoardView extends Component {

  get pointsColumns() {
    return this.props.standings.reduce((acc, standing) => {
      const campaign = standing.campaign;
      const vernacular = campaign.categoryArchetype || 'Category';
      const shortname = campaign.shortname || '';
      acc.push(
        ...campaign.categories.map(category => {
          return {
            campaign: campaign.id,
            category,
            campaignName: shortname,
            title: `${vernacular} ${campaign.categoryDisplayNames[category] || category}`,
            property: `points-${campaign.id}-${category}`,
            openShowcase: () => this.props.openShowcase(campaign.id, category),
          };
        })
      );
      return acc;
    }, []);
  }

  get columns() {
    const expanded = this.pointsColumns.map((column) => {
      return {
        field: column.property,
        headerName: column.title,
        width: 220,
        type: 'number',
        renderHeaderHtml: (params) => {
          return (`
            <div>
              <div>${column.campaignName}</div>
              <div>${column.title}</div>
             </div>
          `);
          
        },
        renderHeader: (params) => {
          return (
            <div className={styles.scoreHeader} onClick={column.openShowcase}>
              <div className={styles.label}>{column.campaignName}</div>
              <div className={styles.label}>{column.title}</div>
            </div>
          );
        },
        renderCell: (params) => {
          if (isNaN(params.value)) {
            return 0;
          }
          return params.value;
        },
      };
    });

    const columns = this.props.view === 'expanded' ? expanded : [];

    return [
      {
        field: 'avatar',
        headerName: '',
        width: 16,
        renderHtml: (params) => {
          if (params.value) {
            const style = `
              display: flex;
              height: 100%;
              width: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url(${new URL(params.value, window.location.href).toString()})`;
            return (`<div style="${style}"></div>`);
          }
          return '<div></div>';
        },
        renderCell: (params) => {
          const styleObj = {
            backgroundImage: `url(${params.value})`
          };
          return (
            <div className={styles.avatar} style={styleObj}></div>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Club',
        width: 160,
        renderCell: (params) => {
          const club = this.props.clubs.find(club => club.id === params.value);
          return club ? club.shortName : params.value;
        },
      },
      ...columns,
      { field: 'total', headerName: 'Total', width: 190, type: 'number', }
    ];
  }

  get data() {
    const data = this.props.standings.reduce((acc, standing) => {
      Object.entries(standing.scores).forEach(([userId, campaignScores]) => {
        this.pointsColumns
          .filter(column => column.campaign === standing.campaign.id)
          .forEach((column) => {
            lodash.set(acc, `${userId}.${column.property}`, campaignScores[column.category]);
          });
      });
      return acc;
    }, {});
    return Object.entries(data)
      .map(([userId, scores]) => {
        return {
          id: userId,
          userId,
          name: this.props.users[userId].club,
          avatar: this.props.users[userId].avatar,
          total: Object.values(scores).reduce(lodash.add, 0),
          ...scores
        };
      });
  }

  handlePrint() {
    const columns = this.props.expanded ? this.expandedColumns : this.columns;
    openGridPrintView(
      this.props.title,
      columns,
      this.data
    );
  }

  renderLeaderBoard() {
    const columns = this.props.expanded ? this.expandedColumns : this.columns;

    const scoreSheetKeyMap = {
      Print: 'command+p',
    };

    const scoreSheetHandlers = {
      Print: this.handlePrint.bind(this)
    };

    return (
      <GlobalHotKeys keyMap={scoreSheetKeyMap} handlers={scoreSheetHandlers}>
        <Box className={styles.scoreSheet}>
          <Typography sx={{ marginTop: '8px', marginLeft: '8px' }} variant="h6">{this.props.title}</Typography>
          <DataGrid
            rows={this.data}
            columns={columns}
            autoPageSize
          />
        </Box>
      </GlobalHotKeys>
    );
  }

  renderLonelyContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Well, back to the drawing board...
        </Typography>
      </div>
    );
  }

  renderNoResultsContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Sadly, all that glitters isn't always gold...
        </Typography>
      </div>
    );
  }

  render() {
    let content;
    if (this.props.initializing) {
      content = (
        <div className={styles.loader}>
          <RingLoader className={styles.loader} color="white" />
        </div>
      );
    } else if (this.props.standings.length === 0) {
      content = this.renderLonelyContent();
    } else {
      content = this.renderLeaderBoard();
    }

    const handleChange = (e) => {
      this.props.switchToView(e.currentTarget.value);
    };


    return (
      <div className={styles.leaderboard}>
        <Header></Header>
        {content}
        <Footer showMoreIcon={false} onPrint={this.handlePrint.bind(this)}>
          <ToggleButtonGroup orientation="horizontal" value={this.props.view} exclusive onChange={handleChange}>
            <ToggleButton value="condensed" aria-label="condensed">
              <Grid3x3ViewIcon />
            </ToggleButton>
            <ToggleButton value="expanded" aria-label="expanded">
              <Grid4x4ViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Footer>
      </div>
    );
  }
}

LeaderBoardView.propTypes = {
  standings: PropTypes.array,
  users: PropTypes.object,
  clubs: PropTypes.array,
  initializing: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  view: PropTypes.oneOf(['condensed', 'expanded']),
  title: PropTypes.string,
};

LeaderBoardView.defaultProps = {
  standings: [],
  users: {},
  clubs: [],
  initializing: true,
  expanded: false,
  view: 'expanded',
  title: 'Best of the Best of the Best'
};

LeaderBoardView.connector = (state) => {
  const initializing = !state.appState.ready;
  const view = state.appState.view;
  const { standings } = state.standings;
  const campaignId = lodash.get(standings, '[0].campaign.id');
  const initiative = campaignId && state.appState.config.campaigns[`${campaignId}`].initiativeName;

  const title = `${initiative} Standings`;

  return {
    title,
    view: ['condensed', 'expanded'].includes(view) ? view : 'expanded',
    standings: state.standings.standings,
    users: state.standings.users,
    clubs: state.standings.clubs,
    initializing,
  };
};

LeaderBoardView.commander = (dispatch) => {
  return {
    switchToView: (view) => dispatch({ type: actions.SET_ACTIVE_VIEW, payload: { view } }),
    openShowcase: (id, category) => dispatch({ type: actions.NAVIGATE, payload: { location: `/gallery/${id}/${category}`, method: 'push' } }),
  };
};

export default connect(
  LeaderBoardView.connector,
  LeaderBoardView.commander
)(LeaderBoardView);
