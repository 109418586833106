import PropTypes from 'prop-types';

const LiveViewConsoleProps = {
    propTypes: {
        logout: PropTypes.func,
        login: PropTypes.func,
        paused: PropTypes.bool,
        initializing: PropTypes.bool,
        eventState: PropTypes.oneOf(['upcoming', 'scoring-test', 'preview-images', 'running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners', 'intermission', 'report-winners', 'choosing-best', 'ended']),
        showState: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        scoringState: PropTypes.oneOf(['disabled', 'picking', 'scoring', 'voting', 'testing']),
        startGame: PropTypes.func,
        prepSleeves: PropTypes.func,
        prepGame: PropTypes.func,
        revealSecrets: PropTypes.func,
        resumeGame: PropTypes.func,
        scoreImage: PropTypes.func,
        voteForImage: PropTypes.func,
        rescoreImage: PropTypes.func,
        eliminateImage: PropTypes.func,
        awardImage: PropTypes.func,
        spotlightImage: PropTypes.func,
        removeSpotlight: PropTypes.func,
        getImageURL: PropTypes.func,
        getImageInfo: PropTypes.func,
        getScoreById: PropTypes.func,
        getVotesById: PropTypes.func,
        getAwardById: PropTypes.func,
        scoringRound: PropTypes.string,
        currentCategory: PropTypes.string,
        totalDqInCategory: PropTypes.number,
        totalImagesInCategory: PropTypes.number,
        totalImagesThisRound: PropTypes.number,
        toGo: PropTypes.number,
        title: PropTypes.string,
        minScore: PropTypes.number,
        maxScore: PropTypes.number,
        totalJudges: PropTypes.number,
        categories: PropTypes.arrayOf(PropTypes.string),
        scoringType: PropTypes.string,
        modality: PropTypes.string,
        labelInfo: PropTypes.object,
        awards: PropTypes.objectOf(PropTypes.string),
        imagesToScore: PropTypes.arrayOf(PropTypes.string),
        imagesInRound: PropTypes.arrayOf(PropTypes.string),
        placesNames: PropTypes.arrayOf(PropTypes.string),
        shortPlacesNames: PropTypes.arrayOf(PropTypes.string),
        awardMap: PropTypes.objectOf(PropTypes.string),
        scores: PropTypes.object,
        categoryDisplayNames: PropTypes.object,
        specialAwardTitle: PropTypes.string,
        specialAwardCategory: PropTypes.string,
        proxy: PropTypes.string,
        isOperator: PropTypes.bool,
        canPrep: PropTypes.bool,
        bestInShow: PropTypes.string,
        eventType: PropTypes.string,
        eventDate: PropTypes.string,
        eventFeatures: PropTypes.arrayOf(PropTypes.string),
        totalHonors: PropTypes.number,
        categoryArchetype: PropTypes.string,
        getCurrentMidCycleReportId: PropTypes.func,
        openDirectory: PropTypes.func,
        testScoringDevices: PropTypes.func,
        getPrintNumberById: PropTypes.func,
        canPrepMidCycleReport: PropTypes.bool,
        prepReport: PropTypes.func,
        previewImagesInRound: PropTypes.func,
    },

    defaultProps: {
        initializing: true,
        paused: false,
        eventState: 'upcoming',
        showState: 'interstitial',
        scoringState: 'disabled',
        logout: () => { },
        login: () => { },
        startGame: () => { },
        prepGame: () => { },
        prepSleeves: () => { },
        revealSecrets: () => { },
        resumeGame: () => { },
        scoreImage: () => { },
        voteForImage: () => { },
        rescoreImage: () => { },
        eliminateImage: () => { },
        awardImage: () => { },
        spotlightImage: () => { },
        removeSpotlight: () => { },
        getImageURL: () => { },
        getImageInfo: () => { },
        getScoreById: () => { },
        getVotesById: () => { },
        getAwardById: () => { },
        scoringRound: "Initial",
        currentCategory: '',
        totalDqInCategory: 0,
        totalImagesInCategory: 0,
        totalImagesThisRound: 0,
        toGo: 0,
        minScore: 0,
        maxScore: 0,
        totalJudges: 1,
        title: "Competition to end all competitions",
        categories: [],
        scoringType: 'automated',
        modality: 'projected-image',
        imagesToScore: [],
        imagesInRound: [],
        placesNames: [],
        shortPlacesNames: [],
        awards: {},
        awardMap: {},
        specialAwardTitle: 'Best in Show',
        specialAwardCategory: null,
        scores: {},
        categoryDisplayNames: {},
        labelInfo: null,
        proxy: null,
        isOperator: false,
        canPrep: false,
        bestInShow: null,
        eventType: 'placed-competition',
        eventFeatures: [],
        totalHonors: 0,
        categoryArchetype: 'Category',
        getCurrentMidCycleReportId: () => { },
        canPrepMidCycleReport: false,
        previewImagesInRound: () => { },
        openDirectory: () => { },
        testScoringDevices: () => { },
        prepReport: () => { },
        getPrintNumberById: () => { },
    },
};

export default LiveViewConsoleProps;
