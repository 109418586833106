import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import styles from './Footer.module.scss';
import { Typography } from '@mui/material';



export default class Footer extends Component {
  constructor(props) {
    super(props);

    this.searchRef = React.createRef();

    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.handleSearchClicked = this.handleSearchClicked.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);

    this.state = {
      menuAnchor: null,
      menuOpen: false,
    };
  }

  handleCloseMenu() {
    this.setMenuState(false);
  }

  handleLogout() {
    this.setMenuState(false);
    this.props.logout();
  }

  handleSearchClicked() {
    this.searchRef.current.focus();
  }

  handleSearchChanged(e) {
    this.props.applyFilter(e.currentTarget.value);
  }

  handleLogin() {
    this.setMenuState(false);
    this.props.login();
  }

  handleOpenMenu(e) {
    this.setMenuState(e);
  }

  setMenuState(e) {
    if (!e) {
      this.setState({
        menuAnchor: null,
        menuOpen: false,
        ready: false
      });
    } else {
      this.setState({
        menuAnchor: e.currentTarget,
        menuOpen: true,
        ready: false
      });
    }
  }

  renderMenu() {
    return (<Menu
      id="footer-menu"
      anchorEl={this.state.menuAnchor}
      open={this.state.menuOpen}
      onClose={this.handleCloseMenu}
    >
      <h5 className={styles.menuTitle}>{this.props.getCampaignNameById(this.props.campaign)}</h5>
      <MenuItem divider />
      {this.props.logout && (<MenuItem onClick={this.handleLogout}>Logout</MenuItem>)}
      {this.props.login && (<MenuItem onClick={this.handleLogin}>Login</MenuItem>)}
    </Menu>);
  }

  render() {
    const hasButtons = this.props.children;
    return (
      <div className={styles.text}>
        <AppBar color="primary" sx={{ position: 'relative' }}>
          <Toolbar style={{ backgroundColor: 'black' }}>
            {this.props.onPrint && <div className={styles.actions}>
              <IconButton variant="outlined" onClick={this.props.onPrint}>
                <PrintIcon />
              </IconButton >
            </div>}

            {hasButtons && <div className={styles.buttons}>
              {this.props.children}
            </div>}

            {this.props.info && <Typography variant="h6" sx={{
              textAlign: 'left',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              // TODO: this is only as good as the buttons width is 48px * 3 = 144px + 16px padding = 160px
              //  if the number of buttons (above in this.child.props) changes or this is used in 
              //  some other context then this will not work...
              maxWidth: 'calc(100% - 160px)',
              overflow: 'hidden',
            }}>{this.props.info}</Typography>}

            {this.props.applyFilter && <div className={styles.searchBox}>
              <TextField
                label="Search"
                inputRef={this.searchRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ color: 'white' }} position="start">
                      <SearchIcon sx={{ color: 'white' }} onClick={this.handleSearchClicked} />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                onChange={this.handleSearchChanged}
                value={this.props.filter}
              />
            </div>}
            {this.props.showMoreIcon && (<IconButton color="inherit" sx={{ '@media(max-width: 400px), (max-height: 800px)': { display: 'none' }, marginTop: '8px', position: 'absolute', right: 0 }} onClick={this.handleOpenMenu}>
              <MoreIcon fontSize="large" />
            </IconButton>)}
          </Toolbar>
        </AppBar>
        {(this.props.logout || this.props.login) && (this.renderMenu())}
      </div>
    );
  }
}

Footer.propTypes = {
  info: PropTypes.string,
  filterContent: PropTypes.string,
  campaign: PropTypes.string,
  getCampaignNameById: PropTypes.func,
  applyFilter: PropTypes.func,
  logout: PropTypes.func,
  login: PropTypes.func,
  showMoreIcon: PropTypes.bool,
  onPrint: PropTypes.func,
};

Footer.defaultProps = {
  info: null,
  showSearch: false,
  filterContent: '',
  campaign: '',
  getCampaignNameById: () => { },
  logout: null,
  login: null,
  showMoreIcon: true,
  onPrint: null,
};
