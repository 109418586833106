import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QrScanner from "qr-scanner";

import lodash from 'lodash';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Interstitial from '../../Components/Interstitial';
import styles from './Scanner.module.scss';

import Header from '../Header';
import Footer from '../Footer';

import AppController from '../../Controllers/AppController';
import DialogController from '../../Controllers/DialogController';

import { mobileCheck } from '../../Utils/DeviceUtils';

import sprite from './sounds/sprite.mp3';

import * as actions from '../../model/actions';

const BREATHE_TIME = 8000;
const RESET_TIME = BREATHE_TIME + 2000;

const getScannerBreathTime = () => {
  return BREATHE_TIME;
};

const getScannerResetTime = () => {
  return RESET_TIME;
};

class Scanner extends Component {
  constructor(props) {
    super(props);

    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleCategorySelect = this.handleCategorySelect.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleVisibilityChanged = this.handleVisibilityChanged.bind(this);

    this.sounds = ['begin', 'start', 'scan', 'error'];

    this.state = {
      openSnackbar: false,
      snackbarMessage: null,
      snackbarSeverity: 'info',
      started: false,
      scanning: true,
      category: '',
      image: '',
      lastScanTime: 0,
      lastScanValue: null,
      showingPrompt: false,
    };

    this.videoElem = React.createRef();
    this.audioElem = React.createRef();
    this.focusableElement = React.createRef();

    AppController.on(AppController.errorEvent, (snackbarMessage) => {
      this.setState(() => {
        this.playSound('error').then(() => {
          return {
            snackbarMessage,
            openSnackbar: true,
          };
        });
      });
    });
  }

  handleFocus() {
    if (this.focusableElement.current) {
      const button = this.focusableElement.current.querySelector('[role=button]');
      if (document.activeElement !== button && button.ariaExpanded !== 'true') {
        button.focus();
      }
    }
  }

  handleCloseSnackbar() {
    this.setState({ openSnackbar: false });
  }

  handleLogout() {
    this.props.logout();
  }

  presentImage(id) {
    this.playSound('scan').then(() => {
      if (this.props.scoringState === 'picking') {
        if (['pick-winners', 'choosing-best'].includes(this.props.eventState)) {
          this.props.awardImage(id);
        } else if (this.props.eventState === 'elimination') {
          this.props.eliminateImage(id);
        }
      } else {
        this.props.presentImage(id);
      }
      this.setState({ image: '' });
    });
  }

  async disqualifyImage(id) {
    this.setState({
      showingPrompt: true
    });
    await this.playSound('scan');
    let message;

    if (this.props.isImageDisqualified(id)) {
      message = 'Reinstate Image?';
    } else {
      message = 'Disqualify Image?';
    }
    const response = await DialogController.doAlert(message, 'Scan Action', 'yesNo');
    if (response === 'yes') {
      this.props.disqualifyImage(id);
    }
    this.setState({
      showingPrompt: false
    });
  }

  handleImageSelect(event) {
    const id = event.target.value;
    if (this.props.scanningFor === 'image' && this.props.eventState === 'upcoming' && this.props.modality === 'printed-image') {
      this.disqualifyImage(id);
    } else {
      this.presentImage(id);
    }
  }

  startCategory(category) {
    this.playSound('start').then(() => {
      this.props.startCategory(category);
      this.setState({ category: '' });
    });
  }

  handleCategorySelect(event) {
    const category = event.target.value;
    this.startCategory(category);
  }

  renderPleaseStandBy() {
    return (
      <Interstitial />
    );
  }

  renderCategorySelector() {
    return (
      <div className={styles.widget}>
        <Box sx={{ minWidth: 120 }}>
          <Typography variant="h6">Select a Category to start</Typography>
          <FormControl fullWidth>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={this.state.category}
              label={this.props.categoryArchetype}
              onChange={this.handleCategorySelect}
              ref={this.focusableElement}
            >
              {this.props.categoriesToScore.map(category => (<MenuItem key={`${category}-category-select`} value={category}>{this.props.categoryDisplayNames[category] || category}</MenuItem>))}
            </Select>
          </FormControl>
        </Box>
      </div>
    );
  }

  renderImageSelector() {
    const imageList = (this.props.scanningFor === 'image' && this.props.eventState === 'upcoming' && this.props.modality === 'printed-image') ? this.props.images : this.props.imagesToScore;
    const images = imageList.map(image => (<MenuItem key={`${image}-image-select`} value={image}>{this.props.getImageInfo(image).title}</MenuItem>));

    return (
      <div className={styles.widget}>
        <Box sx={{ minWidth: 120 }}>
          <Typography variant="h6">Select an Image Title</Typography>
          <FormControl fullWidth>
            <InputLabel id="image-select-label">Image</InputLabel>

            <Select
              labelId="image-select-label"
              id="image-select"
              value={this.state.image}
              label="Image"
              onChange={this.handleImageSelect}
              ref={this.focusableElement}
            >
              {images}
            </Select>
          </FormControl>
        </Box>
      </div>
    );
  }

  stopScanner() {
    console.log('stopping scanner');
    if (this.qrScanner) {
      this.qrScanner.stop();
    } else {
      try {
        console.warn(new Error('stopScanner called but we have no scanner'));
      } catch {
        console.log('ignoring error');
      }
    }
    this.setState(() => {
      return {
        scanning: false,
      };
    });
  }

  startScanner() {
    console.log('staring scanner');
    this.qrScanner.start().then(() => {
      this.setState(() => {
        return {
          scanning: true,
        };
      });
    });
  }

  handleScan(scan) {
    console.log(scan && scan.data);
    if (!this.state.scanning) {
      console.log(`not scanning ${JSON.stringify(this.state)}`);
      return;
    }
    const scanTime = Date.now();
    if (scanTime - this.state.lastScanTime < getScannerBreathTime()) {
      console.log(`breathing...`);
      return;
    }

    if (scanTime - this.state.lastScanTime < getScannerResetTime()
      && scan.data === this.state.lastScanValue) {
      console.log(`waiting for reset...`);
      return;
    }

    this.setState(() => {
      return {
        lastScanValue: scan.data,
        lastScanTime: scanTime,
      };
    });

    const things = scan.data.split('/');
    const [application, value] = things;
    console.log(`checking ${application} with ${value}`);
    if (scan.data.startsWith(`${this.props.scanningFor}+id/`) && value) {
      if (this.props.scanningFor === 'image' && this.props.imagesToScore.includes(value)) {
        this.stopScanner();
        this.presentImage(value);
      } else if (this.props.scanningFor === 'category' && this.props.categoriesToScore.includes(value)) {
        this.stopScanner();
        this.startCategory(value);
      } else if (this.props.scanningFor === 'image' && this.props.eventState === 'upcoming' && this.props.modality === 'printed-image') {
        this.stopScanner();
        this.disqualifyImage(value);
      } else {
        const snackbarMessage = (this.props.scanningFor === 'image')
          ? 'That image has already been scored or did not advance to the current round'
          : `That ${this.props.categoryArchetype} is closed`;

        this.setState(() => {
          this.playSound('error');
          return {
            openSnackbar: true,
            snackbarMessage,
          };
        });
      }
    } else if (['image+id', 'category+id'].includes(application)) {
      const snackbarMessage = (this.props.scanningFor === 'image')
        ? 'An image QR code was expected'
        : 'A Category QR code was expected';
      this.setState(() => {
        this.playSound('error').then(() => {
          return {
            openSnackbar: true,
            snackbarMessage,
          };
        });
      });

    } else {
      this.setState(() => {
        this.playSound('error').then(() => {
          return {
            openSnackbar: true,
            snackbarMessage: "Not something I can process...",
          };
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.mobile) {
      this.handleFocus();
    }
    if (this.props.scanningFor !== prevProps.scanningFor) {
      if (this.props.scanningFor === null) {
        this.stopScanner();
        return;
      }

      this.startScanner();

    } else if (this.props.scoringState === 'picking'
      && this.props.scanningFor === 'image'
      && this.props.imagesToScore !== prevProps.imagesToScore) {
      this.startScanner();
    } else if (this.props.eventState === 'upcoming'
      && this.props.scanningFor === 'image'
      && this.props.disqualifiedImages !== prevProps.disqualifiedImages
      && !this.state.showingPrompt) {
      this.startScanner();
    }
  }

  handleVisibilityChanged() {
    if (document.visibilityState !== 'visible') {
      // reload the document to reset the media element
      window.location.reload();
    }
  }

  componentDidMount() {
    if (!this.props.mobile) {
      this.handleFocus();
    }

    // TODO: add support for visibilityChange to prevent `play` / `pause` controls from showing on lock screen
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event

    // document.addEventListener('visibilitychange', this.handleVisibilityChanged);

    const videoElem = this.videoElem.current;
    this.qrScanner = new QrScanner(videoElem, this.handleScan, {});

    this.qrScanner.start().then(() => {
      if (this.props.scanningFor !== null) {
        this.startScanner();
      } else {
        this.stopScanner();
      }
    });
  }

  renderVideo() {
    const feedbackStyles = {
      opacity: (this.state.started && this.props.scanningFor !== null && !this.state.showingPrompt) ? '1' : '0',
      display: (this.props.mobile) ? 'block' : 'none',
      backgroundColor: this.props.scanningFor === 'image' ? (this.props.eventState === 'upcoming' ? 'red' : 'orange') : 'green',
      borderColor: this.props.scanningFor === 'image' ? (this.props.eventState === 'upcoming' ? 'red' : 'orange') : 'green',
    };
    const styleObj = {
      width: "100%",
      height: "auto",
    };
    const content = this.props.scanningFor === 'image'
      ? 'Scan an image QR Code'
      : 'Scan a Category QR Code';

    return (
      <div className={styles.feedback} style={feedbackStyles}>
        <Typography variant="h4">{content}</Typography>
        <video ref={this.videoElem} style={styleObj}></video >
      </div >
    );
  }

  playSound(audio) {
    if (!this.props.mobile) {
      return Promise.resolve();
    }
    if (this.sounds.indexOf(audio) === -1) {
      console.error(`Unable to play audio -- '${audio}'`);
    }
    const audioElem = this.audioElem.current;
    if (!audioElem) {
      console.error('Audio element has not yet been rendered');
    }

    const offset = this.sounds.indexOf(audio) * 10;
    const end = offset + 5;
    audioElem.currentTime = offset;

    return new Promise((resolveOuter) => {
      const handleAudioPlayerTimeUpdated = () => {
        if (audioElem.currentTime > end || audioElem.ended) {
          audioElem.pause();
        }
      };

      const dispatchOuter = () => {
        audioElem.removeEventListener('timeupdate', handleAudioPlayerTimeUpdated);
        audioElem.removeEventListener('pause', dispatchOuter);
        audioElem.removeEventListener('ended', dispatchOuter);
        resolveOuter();
      };

      const waitForAudioToStop = new Promise((resolveInner) => {
        const dispatchInner = () => {
          audioElem.removeEventListener('timeupdate', dispatchInner);
          audioElem.removeEventListener('pause', dispatchInner);
          audioElem.removeEventListener('ended', dispatchInner);
          resolveInner();
        };

        if (!audioElem.paused && !audioElem.ended) {
          audioElem.addEventListener('timeupdate', dispatchInner);
          audioElem.addEventListener('pause', dispatchInner);
          audioElem.addEventListener('ended', dispatchInner);

          audioElem.pause();
        } else {
          resolveInner();
        }
      });

      waitForAudioToStop.then(() => {

        audioElem.play().then(() => {
          audioElem.addEventListener('pause', dispatchOuter);
          audioElem.addEventListener('ended', dispatchOuter);
          audioElem.addEventListener('timeupdate', handleAudioPlayerTimeUpdated);
          resolveOuter();
        });
      });
    });
  }

  renderStartButton() {
    const styles = {
      zIndex: 10,
      height: '50%',
      backgroundColor: 'dodgerblue',
      color: 'white',
      width: 'calc(100% - 20px)',
      margin: '0 10px',
      transform: 'translateY(-50%)',
      position: 'absolute',
      top: '50%',
      left: 0
    };
    const playStartSound = () => {
      this.playSound('begin').then(() => {
        this.setState(() => {
          return { started: true };
        });
      });
    };
    return (
      <Box sx={{ height: 'calc(100% - 104px)', width: '100%' }}>
        <Button key="startButton" sx={styles} onClick={playStartSound}>
          <Typography variant="h2">
            Start
          </Typography>
        </Button>
      </Box>
    );
  }

  render() {

    return (
      <div className={styles.scanner}>
        <Header></Header>
        <audio ref={this.audioElem} src={sprite}></audio>
        {!this.state.started && this.renderStartButton()}
        {this.state.started && this.props.scanningFor === null && this.renderPleaseStandBy()}
        {this.state.started && !this.props.mobile && this.props.scanningFor === 'image' && this.renderImageSelector()}
        {this.state.started && !this.props.mobile && this.props.scanningFor === 'category' && this.renderCategorySelector()}
        {this.renderVideo()}
        <Footer logout={this.handleLogout}></Footer>
        <Snackbar open={this.state.openSnackbar} autoHideDuration={6000} onClose={this.handleCloseSnackbar}>
          <Alert onClose={this.handleCloseSnackbar} severity={this.state.snackbarSeverity} sx={{ width: '100%' }}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }

}

Scanner.propTypes = {
  logout: PropTypes.func,
  startCategory: PropTypes.func,
  presentImage: PropTypes.func,
  eliminateImage: PropTypes.func,
  disqualifyImage: PropTypes.func,
  awardImage: PropTypes.func,
  paused: PropTypes.bool,
  initializing: PropTypes.bool,
  eventState: PropTypes.oneOf(['upcoming', 'scoring-test', 'preview-images', 'running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners', 'intermission', 'report-winners', 'choosing-best', 'ended']),
  showState: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  scoringState: PropTypes.oneOf(['disabled', 'picking', 'scoring', 'voting', 'testing']),
  scanningFor: PropTypes.oneOf([null, 'image', 'category']),
  getImageInfo: PropTypes.func,
  isImageDisqualified: PropTypes.func,
  scoringRound: PropTypes.string,
  currentCategory: PropTypes.string,
  title: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  scoringType: PropTypes.string,
  modality: PropTypes.string,
  imagesToScore: PropTypes.arrayOf(PropTypes.string),
  disqualifiedImages: PropTypes.arrayOf(PropTypes.string),
  images: PropTypes.arrayOf(PropTypes.string),
  categoriesToScore: PropTypes.arrayOf(PropTypes.string),
  categoryDisplayNames: PropTypes.object,
  mobile: PropTypes.bool,
  categoryArchetype: PropTypes.string,
};

Scanner.defaultProps = {
  initializing: true,
  paused: false,
  eventState: 'upcoming',
  showState: 'interstitial',
  scoringState: 'disabled',
  startCategory: () => { },
  presentImage: () => { },
  eliminateImage: () => { },
  disqualifyImage: () => { },
  awardImage: () => { },
  logout: () => { },
  getImageInfo: () => { },
  isImageDisqualified: () => { return false; },
  scoringRound: "Initial",
  currentCategory: '',
  categoriesToScore: [],
  title: "Competition to end all competitions",
  categories: [],
  scoringType: 'automated',
  modality: 'projected-image',
  imagesToScore: [],
  disqualifiedImages: [],
  images: [],
  categoryDisplayNames: {},
  scanningFor: null,
  mobile: false,
  categoryArchetype: 'category'
};

Scanner.connector = (state) => {
  const categories = state.appState.config.campaigns[state.liveState.campaign].categories;
  const categoryDisplayNames = state.appState.config.campaigns[state.liveState.campaign].categoryDisplayNames;
  const categoryArchetype = state.appState.config.campaigns[state.liveState.campaign].categoryArchetype;
  const initializing = !state.appState.ready || state.appState.throttled;
  const eventState = state.liveState.gamePlay.state;
  const scoringState = state.liveState.gamePlay.scoringState;
  const showState = state.liveState.gamePlay.showState;
  const currentCategory = state.liveState.gamePlay.currentCategory;
  const currentImage = state.liveState.gamePlay.currentImage;
  const { type: scoringType, modality } = state.liveState.scoringSystem;
  const scoringRound = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.type", "");
  const title = state.appState.config.campaigns[state.liveState.campaign].name;
  const imagesToScore = lodash.get(state.liveState.gamePlay.currentScoringSystem, "currentScoringRound.imagesToScore", 0);
  const categoriesToScore = state.liveState.gamePlay.categoriesToScore;
  const images = Object.keys(state.images);
  const disqualifiedImages = state.liveState.gamePlay.dq;

  let scanningFor = null;
  if (!initializing && eventState === 'upcoming' && modality === 'printed-image') {
    // Setup for DQ mode
    scanningFor = 'image';
  } else if (!initializing && ((scoringState === 'disabled' && eventState === 'running') || scoringState === 'picking')) {
    if (eventState === 'choosing-best') {
      scanningFor = 'image';
    } else if (!currentCategory) {
      scanningFor = 'category';
    } else {
      scanningFor = 'image';
    }
  }

  const isImageDisqualified = (id) => disqualifiedImages.includes(id);

  const getImageInfo = (id) => {
    return state.images[id];
  };

  // FIXME: This does not need to be a property
  //         we can compute this in the constructor
  //         we should not use qr scanner if false
  const mobile = mobileCheck();

  return {
    initializing,
    eventState,
    showState,
    scoringState,
    currentImage,
    getImageInfo,
    isImageDisqualified,
    paused: state.liveState.gamePlay.paused,
    scoringRound,
    currentCategory,
    categoryDisplayNames,
    title,
    categories,
    scoringType,
    modality,
    imagesToScore,
    images,
    disqualifiedImages,
    categoriesToScore,
    scanningFor,
    mobile,
    categoryArchetype,
  };
};

Scanner.commander = (dispatch) => {
  const throttle = (command) => {
    dispatch({ type: actions.THROTTLE_COMMAND, payload: command });
  };
  return {
    startCategory: (category) => throttle({ type: actions.LIVE_COMMAND, payload: { command: `start/${category}`, method: 'POST' } }),
    presentImage: (image) => throttle({ type: actions.PRESENT_IMAGE, payload: { image } }),
    eliminateImage: (image) => throttle({ type: actions.ELIMINATE_IMAGE, payload: { image } }),
    disqualifyImage: (id) => dispatch({ type: actions.DISQUALIFY_IMAGE, payload: { image: id } }),
    awardImage: (image) => throttle({ type: actions.AWARD_IMAGE, payload: { image } }),
    logout: () => dispatch({ type: actions.LOGOUT }),
  };
};


export default connect(
  Scanner.connector,
  Scanner.commander
)(Scanner);
