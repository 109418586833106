import React, { Component } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import Cookies from 'js-cookie';

import store from './model/store';
import * as actions from './model/actions';

import Workspace from './Chrome/Workspace';
import backend from './model/backend';

import AppController from './Controllers/AppController';

import "swiper/css/bundle";

class App extends Component {
  constructor(props) {
    super(props);

    AppController.store = store;
    const url = new URL(window.location);
    let access_token = url.searchParams.get('access_token');

    if (access_token) {
      Cookies.set('access_token', access_token, { expires: 2, sameSite: 'strict' });
      url.searchParams.delete('access_token');
      window.history.replaceState({}, '', url);
    } else {
      access_token = Cookies.get('access_token');
    }

    if (access_token) {
      backend.setAccessToken(`Bearer ${access_token}`);
    }

    store.dispatch({ type: actions.INITIALIZE });
  }

  render() {
    return (
      <ReduxProvider store={store}>
        <Workspace />
      </ReduxProvider>
    );
  }
}

export default App;
